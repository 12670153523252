import { console, window } from "globalthis/implementation"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { connect } from "react-redux"
import Activities from "../components/homepage/activities/Activities"
//Homepage components
import Banner from "../components/homepage/Banner"
import Gallery from "../components/homepage/gallery/Gallery"
import HorizontalCarousel from "../components/homepage/HorizontalCarousel"
import Instagram from "../components/homepage/instagram/InstagramGallery"
import IntroductionSlider from "../components/homepage/introduction/IntroductionSlider"
import IntroductionText from "../components/homepage/introduction/IntroductionText"
import Newsletters from "../components/homepage/newsletter/Newsletter"
import Rooms from "../components/homepage/rooms/Rooms"
import Services from "../components/homepage/Services"
import Spa from "../components/homepage/spa/Spa"
import { JsonLd } from "../components/JsonLd"
import SEO from "../components/seo"
import Footer from "../components/share/Footer"

const HorizontalCarouselMemo = React.memo(() => <HorizontalCarousel />)

const ServicesMemo = React.memo(() => <Services />)

const GalleryMemo = React.memo(({ loadingImgs }) => (
  <Gallery loadingImgs={loadingImgs} />
))

const InstagramMemo = React.memo(({ loadingImgs }) => (
  <Instagram loadingImgs={loadingImgs} />
))

const langSeo = {
  fr: {
    title: "Hôtel Au Charme Rabelaisien **** | Site Officiel",
    description:
      "Au Charme Rabelaisien vous acceuille dans son hotel 4 étoiles  à deux pas du Château d'Amboise.  Espace détente & SPA. Meilleurs tarifs garantis.",
  },
  en: {
    title: "Hotel Au Charme Rabelaisien **** | Official Website",
    description:
      "Au Charme Rabelaisien welcomes you to its 4-star hotel a stone's throw from the Château d'Amboise. Relaxation area & SPA. Best rates guaranteed.",
  },
  ru: {
    title: "Hôtel Au Charme Rabelaisien **** | Official Website",
    description:
      "Au Charme Rabelaisien welcomes you to its 4-star hotel a stone's throw from the Château d'Amboise. Relaxation area & SPA. Best rates guaranteed.",
  },
}

const IndexPage = ({
  instaContainerHeight,
  updateNavColor,
  navColor,
  language,
  scrolltriggerReady,
  updateRoomsListColor,
  updateBannerInSight,
  isTouchScreen,
  scrollOffset,
  updateShowArrowDown,
}) => {
  let roomsContainer = useRef(null)
  let spaContainer = useRef(null)
  let mainContainer = useRef(null)
  let footerContainer = useRef(null)
  let newsletter = useRef(null)
  let bannerRef = useRef(null)
  let introRef = useRef(null)
  let HorizontalChicNode = useRef(null)
  let urlLanguage = "fr"

  //state
  const [pageHeight, setPageHeight] = useState("")

  if (typeof window !== "undefined") {
    const url = window.location.pathname
    // setWindowWidth(window.innerWidth)

    if (url.includes("fr")) {
      urlLanguage = "fr"
    } else {
      urlLanguage = "en"
    }
  }
  //tweens
  let roomsContainerColorChangerTween = useRef(null)
  let spaContainerColorChangerTween = useRef(null)
  let footerTween = useRef(null)
  let bannerInSightTween = useRef(null)
  let hiddenNodes = useRef(null)
  let secondHiddenNodes = useRef(null)

  //states
  const [showHiddenNodes, setShowHiddenNodes] = useState(false)
  const [showSecondPart, setShowSecondPart] = useState(false)
  // const [showServicesNode, setShowServicesNode] = useState(false)
  // const [showGalleryNode, setShowGalleryNode] = useState(false)

  useEffect(() => {
    updateBannerInSight(true)
    navColor !== "white" && updateNavColor()
    updateRoomsListColor("#FFFFFF")
    return () => {
      roomsContainerColorChangerTween.current &&
        roomsContainerColorChangerTween.current.kill()
      spaContainerColorChangerTween.current &&
        spaContainerColorChangerTween.current.kill()
      footerTween.current && footerTween.current.kill()
      bannerInSightTween.current && bannerInSightTween.current.kill()
      hiddenNodes.current && hiddenNodes.current.kill()
      secondHiddenNodes.current && secondHiddenNodes.current.kill()
    }
  }, [])

  useEffect(() => {
    const mainContainerConst = mainContainer
    const spaContainerConst = spaContainer
    const roomsContainerConst = roomsContainer

    //Stop playing video when banner out of sight
    ScrollTrigger.getById("pause-video-scroller") &&
      ScrollTrigger.getById("pause-video-scroller").kill()
    bannerInSightTween.current = ScrollTrigger.create({
      trigger: bannerRef,
      id: "pause-video-scroller",
      start: "bottom top",
      onEnter: () => {
        updateBannerInSight(false)
      },
      onEnterBack: () => {
        updateBannerInSight(true)
      },
    })

    //rooms
    if (scrolltriggerReady) {
      ScrollTrigger.getById("roomsContainer-color-changer") &&
        ScrollTrigger.getById("roomsContainer-color-changer").kill()
      roomsContainerColorChangerTween.current = ScrollTrigger.create({
        id: "roomsContainer-color-changer",
        trigger: roomsContainerConst,
        start: "top 50%",
        end: "bottom 30%",
        onEnter: () => {
          updateRoomsListColor("#f5eaea")
          // gsap.to(mainContainerConst, { duration: 3.0, backgroundColor: '#f5eaea'})
        },
        onEnterBack: x => {
          updateRoomsListColor("#f5eaea")
          // gsap.to(mainContainerConst, { duration: 3.0, backgroundColor: '#f5eaea'})
        },
        onLeaveBack: x => {
          updateRoomsListColor("#EFE8E3")
          // gsap.to(mainContainerConst, { duration: 3.0, backgroundColor: 'white'})
        },
        onLeave: () => {
          updateRoomsListColor("#FAF4F2")
          // gsap.to(mainContainerConst, { duration: 3.0, backgroundColor: '#FAF4F2'})
        },
      })

      // spa
      ScrollTrigger.getById("spacontainer-update-color") &&
        ScrollTrigger.getById("spacontainer-update-color").kill()
      spaContainerColorChangerTween.current = ScrollTrigger.create({
        id: "spacontainer-update-color",
        trigger: spaContainerConst,
        start: "top 50%",
        end: "bottom 50%",
        onEnterBack: x => {
          updateRoomsListColor("#FAF4F2")
          // gsap.to(mainContainerConst, { duration: 3.0, backgroundColor: '#FAF4F2'})
        },
        onLeave: () => {
          updateRoomsListColor("white")
          // gsap.to(mainContainerConst, { duration: 1.0, backgroundColor: 'white'})
        },
      })

      //HIDDEN DOM NODES
      //hides some nodes below unless user scrolls
      hiddenNodes.current =
        ScrollTrigger.getById("show-hidden-nodes") &&
        ScrollTrigger.getById("show-hidden-nodes").kill()
      ScrollTrigger.create({
        id: "show-hidden-nodes",
        trigger: bannerRef,
        start: "50% top",
        onEnter: x => {
          setShowHiddenNodes(true)
        },
      })

      secondHiddenNodes.current =
        ScrollTrigger.getById("intro-nodes") &&
        ScrollTrigger.getById("intro-nodes").kill()
      let scrollTrig = ScrollTrigger.create({
        id: "intro-nodes",
        trigger: introRef,
        start: "top bottom",
        onEnter: x => {
          setShowSecondPart(true)
        },
      })

      setPageHeight(scrollTrig["scroller"]["scrollHeight"])

      //footer
      //set timeout because I need it from the instagram component to mount first
      // setTimeout(function(){

      //footer markers were off because insta section height was updating after index was mounted
      //i had to use redux to update section height so that markers were at the good place
      if (instaContainerHeight) {
        let height = window.innerHeight

        gsap.set(footerContainer, { yPercent: -60 })

        const uncover = gsap.timeline({ paused: true })

        uncover.to(footerContainer, { yPercent: 0, ease: "none" })

        footerTween.current = ScrollTrigger.create({
          trigger: newsletter,
          start: "70% 70%",
          end: `bottom 60%`,
          animation: uncover,
          scrub: true,
        })
      }
    }
  }, [instaContainerHeight, scrolltriggerReady])

  useEffect(() => {
    if (scrolltriggerReady) {
      if (pageHeight && pageHeight - scrollOffset < window.innerHeight + 100) {
        updateShowArrowDown(false)
      } else {
        updateShowArrowDown(true)
      }
    }
  }, [scrollOffset, scrolltriggerReady])

  const isSSR = typeof window === "undefined"

  return (
    <div style={{ height: "100%" }}>
      <div
        ref={el => (mainContainer = el)}
        style={{ position: "relative", height: "100%" }}
      >
        <JsonLd>
          {{
            "@context": "https://schema.org",
            "@type": "Hotel",
            name: "Au Charme Rabelaisien",
            image:
              "https://res.cloudinary.com/dwxqu39bt/image/upload/v1610921300/rose-facade-social.jpg",
            "@id": "http://www.au-charme-rabelaisien.com/",
            url: "http://www.au-charme-rabelaisien.com/",
            telephone: "+33(0)247575384",
            priceRange: "145 euros - 300 euros",
            address: {
              "@type": "PostalAddress",
              streetAddress: "25 rue Rabelais",
              addressLocality: "Amboise",
              postalCode: "37400",
              addressCountry: "FR",
            },
          }}
        </JsonLd>
        <Helmet>
          <script>
            {
              // !isSSR ? (
              //   function () {
              //   var e,i=["https://fastbase.com/fscript.js","VgZkoHjlIa","script"],a=document,s=a.createElement(i[2]);
              //   s.async=!0,s.id=i[1],s.src=i[0],(e=a.getElementsByTagName(i[2])[0]).parentNode.insertBefore(s,e)
              //   })()
              //   :null
            }
          </script>
        </Helmet>
        <SEO
          title={langSeo[urlLanguage]["title"]}
          // title={'hello from this title!'}
          description={langSeo[urlLanguage]["description"]}
        />
        <div ref={el => (bannerRef = el)}>
          <div id="banner-node">
            <Banner />
          </div>
        </div>
        <div id="introtext-node" ref={el => (introRef = el)}>
          <IntroductionText />
        </div>

        <div id="slider-node">
          <IntroductionSlider showHiddenNodes={showHiddenNodes} />
        </div>
        {!isTouchScreen && (
          <div
            ref={el => (HorizontalChicNode = el)}
            // style={{height: '150vh', marginBottom: '10vh 0'}}
            className="horizontal-chic-node"
          >
            {showHiddenNodes && <HorizontalCarouselMemo />}
          </div>
        )}

        <div className="services-node">
          {showHiddenNodes && <ServicesMemo />}
        </div>

        <div ref={el => (roomsContainer = el)}>
          <div id="room-node">
            <Rooms loadingImgs={showSecondPart} />
          </div>
        </div>
        <div ref={el => (spaContainer = el)}>
          <section>
            <div id="spa-node">
              <Spa loadingImgs={showSecondPart} />
            </div>
          </section>
        </div>
        <div
        // className='gallery-node'
        >
          {<GalleryMemo loadingImgs={showHiddenNodes} />}
        </div>
        <div id="activity-node">
          <Activities />
        </div>

        <section className="insta" style={{ height: instaContainerHeight }}>
          <div id="instagram-node">
            <InstagramMemo loadingImgs={showHiddenNodes} />
          </div>
        </section>
        <div
          style={{ position: "relative", zIndex: 1 }}
          ref={el => (newsletter = el)}
        >
          <div id="newsletter-node">
            <Newsletters bgColor={"white"} />
          </div>
        </div>
        <div
          ref={el => (footerContainer = el)}
          style={{ position: "relative", zIndex: 0 }}
          // ref ={ el => footerContainer = el}
        >
          <div id="footer-node">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  instaContainerHeight,
  navColor,
  scrolltriggerReady,
  language,
  isTouchScreen,
  scrollOffset,
}) => {
  return {
    instaContainerHeight,
    navColor,
    scrolltriggerReady,
    language,
    isTouchScreen,
    scrollOffset,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    updateNavColor: () =>
      dispatch({ type: `UPDATE_NAV_COLOR`, payload: "white" }),
    updateShowArrowDown: bool =>
      dispatch({ type: "UPDATE_SHOW_ARROW_DOWN", payload: bool }),
    updateRoomsListColor: color =>
      dispatch({ type: "UPDATE_ROOM_LIST_COLOR", payload: color }),
    updateBannerInSight: bool =>
      dispatch({ type: "UPDATE_BANNER_SIGHT", payload: bool }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage)
