import React, { useEffect, useRef } from "react"
import { Image } from "../../gatsby-images/image"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Trans } from "@lingui/macro"
import SpaLink from "./SpaLink"
import { connect } from "react-redux"
import { window } from "globalthis/implementation"

const Spa = ({ scrolltriggerReady, loadingImgs }) => {
  let panelsRef = useRef([])
  let textsRef = useRef([])
  let titlesRef = useRef([])
  let fixBox = useRef(null)
  let lastPhoto = useRef(null)
  let imagesDivs = useRef([])

  let spaContainer = useRef(null)

  // const revealRefs = useRef([]);
  panelsRef.current = []
  textsRef.current = []
  titlesRef.current = []

  const addToPanelsRef = el => {
    if (el && !panelsRef.current.includes(el)) {
      panelsRef.current.push(el)
    }
  }

  const addToTitlesRef = el => {
    if (el && !titlesRef.current.includes(el)) {
      titlesRef.current.push(el)
    }
  }

  const addToTextsRef = el => {
    if (el && !textsRef.current.includes(el)) {
      textsRef.current.push(el)
    }
  }

  const addToImagesDivs = el => {
    if (el && !imagesDivs.current.includes(el)) {
      imagesDivs.current.push(el)
    }
  }

  //tweens
  let bigScreenFixboxTween = useRef(null)

  useEffect(() => {
    const windowInnerWidth = window.innerWidth
    const imagesDivsConst = imagesDivs.current

    if (scrolltriggerReady) {
      if (windowInnerWidth > 576) {
        bigScreenFixboxTween.current && bigScreenFixboxTween.current.kill()
        ScrollTrigger.getById("fixbox-spa-big") &&
          ScrollTrigger.getById("fixbox-spa-big").kill()
        bigScreenFixboxTween.current = gsap.timeline({
          scrollTrigger: {
            id: "fixbox-spa-big",
            trigger: fixBox,
            pin: fixBox,
            start: "top 10%",
            endTrigger: lastPhoto,
            end: "30% 30%",
            toggleActions: "play none none reset",
          },
        })

        var changeText = panelsRef.current
        var newText = textsRef.current
        var newTitle = titlesRef.current

        changeText.forEach(function (changeText, index) {
          ScrollTrigger.getById(`st-change-text-${index}`) &&
            ScrollTrigger.getById(`st-change-text-${index}`).kill()
          let st = ScrollTrigger.create({
            id: `st-change-text-${index}`,
            trigger: changeText,
            start: "top 50%",
            end: "bottom 50%",
            onEnter: function () {
              gsap.to([newText[index], newTitle[index]], {
                css: { visibility: "visible", autoAlpha: 1 },
                duration: 0.1,
              })
            },
            onEnterBack: function () {
              gsap.to([newText[index], newTitle[index]], {
                css: { visibility: "visible", autoAlpha: 1 },
                duration: 0.1,
              })
            },
            onLeave: function () {
              if (index != 2) {
                gsap.to([newText[index], newTitle[index]], {
                  css: { visibility: "hidden" },
                  duration: 0.1,
                })
              }
            },
            onLeaveBack: function () {
              if (index != 0) {
                gsap.to([newText[index], newTitle[index]], {
                  css: { visibility: "hidden" },
                  duration: 0.1,
                })
              }
            },
          })
        })
      } else {
        const imgHeight = imagesDivsConst[0].getBoundingClientRect().height
        const screenHeight = window.innerHeight
        imagesDivsConst.map(img => {
          const gastbyWrapper = img.children[0]
          gsap.fromTo(
            img,
            { scale: 1.2 },
            {
              scrollTrigger: {
                trigger: img,
                start: "top 100%",
                scrub: true,
                // end: 'bottom top'
                end: `${screenHeight}px`,
              },
              yPercent: -25,
            }
          )
        })
      }
    }
  }, [scrolltriggerReady])

  useEffect(() => {
    return () => {
      bigScreenFixboxTween.current && bigScreenFixboxTween.current.kill()
      panelsRef.current.map((num, i) => {
        ScrollTrigger.getById(`st-change-text-${i}`) &&
          ScrollTrigger.getById(`st-change-text-${i}`).kill()
      })
    }
  }, [])

  return (
    <div className="spa-container" ref={el => (spaContainer = el)}>
      <div className="row">
        <div
          className="offset-2"
          style={{ fontSize: "calc(12px + 0.4vw)", textTransform: "uppercase" }}
        >
          <Trans>DÉTENTE</Trans>
        </div>
      </div>
      <div className="row">
        <div className="offset-2 title">
          <h2>
            <Trans>L'ACR</Trans>
          </h2>
          <h2>
            <Trans>Spa</Trans>
          </h2>
          <div
            style={{ marginTop: "10vw", minWidth: 50 }}
            className="line col-1"
          ></div>
          <p style={{ marginLeft: 15 }} className="col-sm-6 description">
            <Trans>
              Pour votre détente et votre bien-être, l’hôtel ACR s’est doté d’un
              espace bien-être avec jaccuzzi et sauna à accès privatif.
            </Trans>
          </p>
        </div>
      </div>
      <div className="photos-container">
        <div className="d-none d-sm-block">
          <div
            className="fix-box"
            style={{ boxShadow: "0px 0px 35px -25px" }}
            ref={el => (fixBox = el)}
          >
            <div className="box-title">
              <div className="titles">
                <p
                  ref={el => addToTitlesRef(el)}
                  style={{ visibility: "visible" }}
                >
                  <Trans>Massages</Trans>
                </p>
                <p
                  ref={el => addToTitlesRef(el)}
                  style={{ visibility: "hidden" }}
                >
                  <Trans>Jaccuzi</Trans>
                </p>
                <p
                  ref={el => addToTitlesRef(el)}
                  style={{ visibility: "hidden" }}
                >
                  <Trans>Sauna</Trans>
                </p>
                <div className="line-separation"></div>
              </div>
            </div>
            <div className="text">
              <div className="text-layer">
                <p
                  ref={el => addToTextsRef(el)}
                  style={{ visibility: "visible" }}
                  className="small-descroption"
                >
                  <Trans>
                    Rayonnez et laissez vos tensions disparaître entre les mains
                    expertes de nos spécialistes, au gré de soins exceptionnels.
                  </Trans>
                </p>
                <p
                  ref={el => addToTextsRef(el)}
                  style={{ visibility: "hidden" }}
                  className="small-descroption"
                >
                  <Trans>
                    L’immersion dans cette eau chaude à remous procure une
                    sensation immédiate de détente.
                  </Trans>
                </p>
                <p
                  ref={el => addToTextsRef(el)}
                  style={{ visibility: "hidden" }}
                  className="small-descroption"
                >
                  <Trans>
                    Le sauna offre une sensation de purification. Il stimule la
                    circulation sanguine, offre une détente physique et mentale
                    complète.
                  </Trans>
                </p>
              </div>
            </div>
            <div style={{ marginTop: "2vw" }} className="link">
              <SpaLink />
            </div>
          </div>
        </div>
        {
          // windowWidth < 577 &&
          <>
            <div className="row">
              <div className="col-sm-10 offset-sm-1">
                <div
                  className="photo new pannel"
                  ref={el => addToPanelsRef(el)}
                >
                  <div className="d-block d-sm-none">
                    <div className="spa-box-description">
                      <div>
                        <div className="title">Massage</div>
                        <div className="line-separation"></div>
                      </div>
                      <div className="text">
                        <Trans>
                          Rayonnez et laissez vos tensions disparaître entre les
                          mains expertes de nos spécialistes, au gré de soins
                          exceptionnels.
                        </Trans>
                      </div>
                      <div className="link">
                        <SpaLink />
                      </div>
                    </div>
                  </div>
                  <div className="overflow-div">
                    <div ref={el => addToImagesDivs(el)}>
                      {loadingImgs && <Image src="massage" />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-10 offset-sm-1">
                <div
                  className="photo new pannel"
                  style={{ position: "relative" }}
                  ref={el => addToPanelsRef(el)}
                >
                  <div className="d-block d-sm-none">
                    <div className="spa-box-description">
                      <div>
                        <div className="title">Jaccuzzi</div>
                        <div className="line-separation"></div>
                      </div>
                      <div className="text">
                        <Trans>
                          L’immersion dans cette eau chaude à remous procure une
                          sensation immédiate de détente.
                        </Trans>
                      </div>
                      <div className="link">
                        <SpaLink />
                      </div>
                    </div>
                  </div>
                  <div className="overflow-div">
                    <div ref={el => addToImagesDivs(el)}>
                      {loadingImgs && <Image src="jaccuzzi-spa-component" />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" ref={el => (lastPhoto = el)}>
              <div className="col-sm-10 offset-sm-1">
                <div
                  className="photo new pannel"
                  ref={el => addToPanelsRef(el)}
                >
                  <div className="d-block d-sm-none">
                    <div className="spa-box-description">
                      <div>
                        <div className="title">Sauna</div>
                        <div className="line-separation"></div>
                      </div>
                      <div className="text">
                        <Trans>
                          Le sauna offre une sensation de purification. Il
                          stimule la circulation sanguine, offre une détente
                          physique et mentale complète.
                        </Trans>
                      </div>
                      <div className="link">
                        <SpaLink />
                      </div>
                    </div>
                  </div>
                  <div className="overflow-div">
                    <div ref={el => addToImagesDivs(el)}>
                      {loadingImgs && <Image src="sauna-spa" />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      </div>
    </div>
  )
}

const mapStateToProps = ({ scrolltriggerReady }) => {
  return { scrolltriggerReady }
}

export default connect(mapStateToProps, null)(Spa)
