import React, { useState, useRef } from "react"
import useForm from "./useForm"
import { Trans } from "@lingui/macro"
import { console } from "globalthis/implementation"
import axios from "axios"
import * as qs from "query-string"

function validate(values) {
  let errors = {}
  if (!values.email) {
    errors.email = "Email address is required"
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email address is invalid"
  }
  return errors
}

const Newsletter = ({ bgColor }) => {
  const [success, setSucess] = useState(false)

  const { values, errors, handleChange, handleSubmit } = useForm(
    login,
    validate
  )

  let formRefs = useRef([])

  const addToFormRefs = el => {
    if (el && !formRefs.current.includes(el)) {
      formRefs.current.push(el)
    }
  }

  function login(e) {
    if (errors.email) {
      errors.email = ""
    }
    e.preventDefault()

    const formData = {}

    formRefs.current.map(key => (formData[key.name] = key.value))
    const axiosOptions = {
      url: window.location.pathname,
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: qs.stringify(formData),
    }

    axios(axiosOptions)
      .then(response => {
        formRefs.current.map(el => (el.value = ""))
        setSucess(true)
      })
      .catch(err => console.log(err, "error submitting form"))
  }

  return (
    <div
      className="newsletter"
      style={bgColor === "black" ? { backgroundColor: "#202020" } : null}
    >
      {/* <div className="row">
        <p className='title'>NEWSLETTER</p>
    </div> */}
      <p
        className="first-line"
        style={bgColor === "black" ? { color: "white" } : null}
      >
        <Trans>Inscrivez-vous à</Trans>
      </p>
      <p
        className="second-line"
        style={bgColor === "black" ? { color: "white" } : null}
      >
        <Trans>notre newsletter</Trans>
      </p>
      <form
        onSubmit={e => login(e)}
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="newsletter"
      >
        <input type="hidden" name="bot-field" ref={addToFormRefs} />
        <label
          htmlFor="email"
          style={{
            textTransform: "uppercase",
            color: bgColor === "black" ? "white" : "black",
          }}
        >
          <Trans>Votre addresse email</Trans>
        </label>
        <input
          autoComplete="off"
          className={bgColor === "black" ? "field black-news" : "field"}
          type="email"
          name="email"
          onChange={handleChange}
          ref={addToFormRefs}
          // placeholder='VOTRE ADDRESSE EMAIL'
          value={values.email || ""}
          required
        />
        <button
          type="submit"
          className={bgColor === "black" ? "confirm black-news" : "confirm"}
        >
          <Trans>OK</Trans>
        </button>
      </form>
      <div className="message-area">
        {errors.email && <p className="error">{errors.email}</p>}
        {success && (
          <p className="success">
            <Trans>Congratulation! You are now registered !</Trans>
          </p>
        )}
      </div>
    </div>
  )
}

export default Newsletter
