import React, { useState, useRef, useEffect } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { connect } from "react-redux"

//ddd
import { Image } from "../../gatsby-images/image"

import roomsList from "../../../data/rooms-slider.json"

import gsap from "gsap"

import { Carousel } from "react-bootstrap"

const RoomSlider = ({
  isTouchScreen,
  updateShowCursors,
  showIntroCursors,
  language,
  scrollOffset,
  showHiddenNodes,
}) => {
  const [cursorAnimation, setCursorAnimation] = useState(false)

  const [mouseInside, setMouseInside] = useState("")

  const [carouselHeight, setCarouselHeight] = useState("200px")

  const [slideIndex, setSlideIndex] = useState(1)

  let slider = useRef()
  let sliderContainer = useRef()
  const carousel = useRef()
  const secondCarousel = useRef()
  let smallSliders = useRef([])

  const addToSmallSliders = el => {
    if (el && !smallSliders.current.includes(el)) {
      smallSliders.current.push(el)
    }
  }

  let hideCircle = () => {
    gsap.to(slider, {
      duration: 0.5,
      ease: "power2.out",
      opacity: 0,
    })
  }

  let showCircle = () => {
    gsap.to(slider, {
      duration: 0.5,
      ease: "power2.out",
      opacity: 1,
    })
  }

  let changeSlideNext = e => {
    showIntroCursors && updateShowCursors(false)
    carousel.current.prev()
    secondCarousel.current.prev()
  }

  let changeSlidePrev = e => {
    showIntroCursors && updateShowCursors(false)
    carousel.current.next()
    secondCarousel.current.next()
  }

  useEffect(() => {
    setCarouselHeight(
      `${carousel.current.element.getBoundingClientRect().height}px`
    )
  }, [carousel])

  let flipCursor = side => {
    if (mouseInside === side) {
      return
    } else if (mouseInside === "right") {
      gsap.to(slider, { rotation: 180 })
      setMouseInside("left")
    } else if ((mouseInside === "") & (side === "left")) {
      gsap.to(slider, { rotation: 180 })
      setMouseInside("left")
    } else if ((mouseInside === "left") & (side === "right")) {
      gsap.to(slider, { rotation: 0 })
      setMouseInside("right")
    } else if ((mouseInside === "right") & (side === "left")) {
      gsap.to(slider, { rotation: 180 })
      setMouseInside("left")
    } else {
      setMouseInside(side)
    }
  }

  let moveCircle = e => {
    gsap.to(slider, {
      duration: 0.8,
      ease: "power2.out",
      css: {
        left: e.pageX,
        top: e.screenY - 50 + scrollOffset,
      },
    })
  }

  useEffect(() => {}, [])
  useEffect(() => {
    //if devise is touchscreen have cursors appear
    isTouchScreen && updateShowCursors(true)
  }, [isTouchScreen])

  useEffect(() => {
    const smallSlidersConst = smallSliders.current
    if (showIntroCursors) {
      gsap.set(smallSlidersConst[0], { rotate: -180 })
      gsap.set(smallSlidersConst, { visibility: "visible" })
      const animation = gsap.fromTo(
        smallSlidersConst,
        { scale: 0.3, opacity: 0.9 },
        {
          ease: "power2.out",
          scale: 1,
          duration: 1,
          repeat: -1,
          delay: 1,
          yoyo: true,
        }
      )
      setCursorAnimation(true)
    } else if (!showIntroCursors && cursorAnimation) {
      //cursors have been clicked and are now hidden
      gsap.to(smallSlidersConst, { visibility: "hidden" })
    }
  }, [isTouchScreen, showIntroCursors])

  var removeByIndex = (array, index) => array.filter((_, i) => i !== index)
  let roomsListLang = roomsList.filter(el => el[language])[0][language]
  var first = roomsListLang[0]
  let modifiedRoomsList = removeByIndex(roomsListLang.concat(first), 0)
  return (
    <>
      <div ref={e => (slider = e)} className="ball"></div>
      <div className="">
        <div className="">
          <div
            className="slider-container"
            onMouseLeave={() => hideCircle()}
            onMouseEnter={() => !isTouchScreen && showCircle()}
          >
            <div
              className="ball small-left"
              style={{ left: "calc(20vw + 25px)", top: "calc(50% + 55px)" }}
              ref={el => addToSmallSliders(el)}
            ></div>
            <div
              className="ball small-right"
              style={{ right: "calc( 25px)", top: "calc(50% + 55px)" }}
              ref={el => addToSmallSliders(el)}
            ></div>
            <div
              className="left-slider"
              ref={sliderContainer}
              onMouseEnter={e => {
                flipCursor("left")
              }}
              onClick={e => changeSlideNext(e)}
              onMouseMove={e => {
                moveCircle(e)
              }}
              style={{
                width: "50%",
                cursor: "none",
                zIndex: 100,
                height: carouselHeight,
                zIndex: 100,
                position: "absolute",
              }}
            ></div>
            <div
              className="right-slider"
              ref={sliderContainer}
              onMouseEnter={e => {
                flipCursor("right")
              }}
              onClick={e => changeSlidePrev(e)}
              onMouseMove={e => {
                moveCircle(e)
              }}
              style={{
                width: "50%",
                cursor: "none",
                zIndex: 100,
                height: carouselHeight,
                zIndex: 100,
                position: "absolute",
                marginLeft: "50%",
              }}
            ></div>
            <div className="sliders row">
              <div className="slide-number d-none d-sm-block col-sm-1">
                <p className="active-slide">
                  <strong>{slideIndex}</strong>
                </p>
                <p
                  style={{ marginBottom: "0.7rem" }}
                >{`/ ${roomsListLang.length}`}</p>
              </div>
              <div className="first-carousel col-xs-8 col-sm-7">
                <Carousel
                  interval={1000000}
                  indicators={false}
                  prevIcon={<div />}
                  nextIcon={<div />}
                  ref={carousel}
                  onSelect={index => setSlideIndex(index + 1)}
                >
                  {roomsListLang.map(list => (
                    <Carousel.Item key={list.src}>
                      <div style={{ height: "65vw" }}>
                        {showHiddenNodes && (
                          <Image key={list.src} src={list.src} />
                        )}
                      </div>
                      <Carousel.Caption>
                        <p>{list.Title}</p>
                        <p>{list.description}</p>
                        <p className="award-cat">{list.awardCategory}</p>
                      </Carousel.Caption>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
              <div className="second-carousel col-xs-4">
                <Carousel
                  interval={1000000}
                  indicators={false}
                  prevIcon={<div />}
                  nextIcon={<div />}
                  ref={secondCarousel}
                >
                  {modifiedRoomsList.map(list => (
                    <Carousel.Item key={list.src}>
                      {showHiddenNodes && (
                        <Image key={list.src} src={list.src} />
                      )}
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({
  isTouchScreen,
  showIntroCursors,
  language,
  scrollOffset,
}) => {
  return { isTouchScreen, showIntroCursors, language, scrollOffset }
}

const mapDispatchToProps = dispatch => {
  return {
    updateShowCursors: bool =>
      dispatch({ type: `UPDATE_SHOW_INTRO_CURSORS`, payload: bool }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomSlider)
