import React, { useState, useRef, useEffect } from "react"
// import { withPrefix } from 'gatsby';
import { Image } from "../../gatsby-images/image"
import { connect } from "react-redux"
import ReactPlayer from "react-player"
import gsap from "gsap/gsap-core"
import { Trans } from "@lingui/macro"
import { console } from "globalthis/implementation"

// import {IntroAcr} from '../../../images/intro-acr.mp4'

// const getVideoSrc = width => {
//     if (width >= 1080) return `${withPrefix('uliana-walking-pool.MP4')}`;
//     if (width >= 720) return `${withPrefix('uliana-walking-pool.MP4')}`;
//     return `${withPrefix('uliana-walking-pool.MP4')}`;
//   };

const Video = ({
  bannerLoaded,
  isVideoPlaying,
  updateIsVideoPlaying,
  isBannerInSight,
  mainImageLoaded,
  isTouchScreen,
}) => {
  const [isVideoLoaded, setIsVideoLoaded] = React.useState(false)
  const [isSafariIos, setIsSafariIos] = React.useState(false)
  let thumbVideo = useRef(null)

  let src

  const [readyToPlay, setReadyToPlay] = useState(false)

  // if (typeof window !== `undefined`) {
  //     src= getVideoSrc(window.innerWidth);
  // }

  const fullyLoaded = () => {
    // bannerLoaded()
  }

  const onLoadedData = () => {
    setIsVideoLoaded(true)
  }

  const [videoInSight, setVideoInSight] = useState(true)

  // useEffect(() => {
  //     if(isBannerInSight){
  //       setVideoInSight(true)
  //     } else {
  //       setVideoInSight(false)
  //     }
  //   }, [isBannerInSight])

  useEffect(() => {
    if (isVideoPlaying) {
      gsap.to(thumbVideo, { autoAlpha: 0, duration: 1 })
    } else {
      gsap.to(thumbVideo, { autoAlpha: 1, duration: 1 })
      // !isTouchScreen && screenfull.exit()
    }
  }, [isVideoPlaying])

  useEffect(() => {
    // setTimeout(() => setIsVideoLoaded(true), 800)
    // setTimeout(() => {setReadyToPlay(true)}, 4000)
    setIsSafariIos(
      /iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    )
  }, [])

  return (
    <div
      className="video-container"
      style={{
        zIndex: isVideoPlaying ? 30 : 0,
        backgroundColor: "black",
        objectFit: "contain",
      }}
    >
      <div
        onClick={() => {
          !isVideoPlaying && updateIsVideoPlaying(!isVideoPlaying)
        }}
      >
        <div
          className="thumb-image"
          // loaded={fullyLoaded}
          className="video-thumb tiny"
          alt="thumb"
          style={{ opacity: isVideoLoaded ? 0 : 1 }}
        >
          <Image src="banner-image" loaded={() => fullyLoaded()} />
        </div>
        <div ref={el => (thumbVideo = el)}>
          <div className="thumb-video">
            {/* {mainImageLoaded && */}
            {/* <   ReactPlayer 
                        // url='https://res.cloudinary.com/dwxqu39bt/video/upload/v1612400731/intro-video-short_2.mp4'
                        // url='https://storyxpress.co/v_link/kkv1cm8nci7e2d0ul'
                        // url='https://storyxpress.co/v_link/kle97ibckuxl9osvp'
                        url='https://res.cloudinary.com/dd36wb7ve/video/upload/v1614082664/1a6c0eb1c7243e_files_1612728723899-clapboard-kkv1cm8nci7e2d0ul_cewm5r.mp4'
                        onReady={onLoadedData}
                        controls={false}
                        playsinline
                        playing={isBannerInSight ? true: false}
                        muted={true}
                        width={'100vw'}
                        height={'100vh'}
                        loop={true}
                        style={{position: 'absolute', top: 0, objectFit: 'cover', pointerEvents: 'none'}}
                    /> */}

            {
              // isVideoLoaded &&
              <ReactPlayer
                // url={IntroAcr}
                // url='https://storyxpress.co/v_link/kkv1cm8nci7e2d0ul'
                // url='https://storyxpress.co/v_link/kle97ibckuxl9osvp'
                url={
                  !isTouchScreen
                    ? "https://res.cloudinary.com/dd36wb7ve/video/upload/v1614238553/1a6c0eb1c7243e_files_1612728723899-clapboard-kkv1cm8nci7e2d0ul_1_1_mep0kf.mp4"
                    : "https://res.cloudinary.com/dd36wb7ve/video/upload/v1614932509/trimmed-welcome-video_ddcngt.mp4"
                }
                onReady={onLoadedData}
                controls={false}
                playsinline
                playing={isBannerInSight ? true : false}
                muted={true}
                width={"100vw"}
                height={"100vh"}
                loop={true}
                style={{
                  position: "absolute",
                  top: 0,
                  objectFit: "cover",
                  pointerEvents: "none",
                }}
              />
            }
          </div>
        </div>
      </div>
      {!isSafariIos && isVideoPlaying && (
        <div
          onClick={() => updateIsVideoPlaying(false)}
          style={{
            position: "absolute",
            top: "10%",
            right: "10%",
            color: "#f2f2f2",
            // pointerEvents: 'none',
            fontSize: "16px",
            textDecoration: "underline",
            zIndex: 20000,
            mixBlendMode: "difference",
            padding: "3vw",
          }}
        >
          <Trans>FERMER LA VIDEO</Trans>
        </div>
      )}
      {isVideoPlaying && (
        <ReactPlayer
          // url='https://res.cloudinary.com/dwxqu39bt/video/upload/v1612395017/welcome-acr.mp4'
          url="https://storyxpress.co/v_link/kkxpqpk9i1cv1woxq"
          // loop={false}
          // className='react-player'
          // onPlay={() => isTouchScreen && screenfull.request()}
          controls={!isTouchScreen ? true : false}
          playing={isVideoPlaying && isBannerInSight ? true : false}
          muted={!isVideoPlaying ? true : false}
          width={"100%"}
          height={"100%"}
          onEnded={() => updateIsVideoPlaying(false)}
          style={{
            position: "absolute",
            top: 0,
            objectFit: "cover",
            zIndex: 3000,
          }}
          // playsinline
          // config={{ vimeo: { playerOptions: { background: true }}}}
          onPause={() => {
            isSafariIos && updateIsVideoPlaying(false)
          }}
        />
      )}
    </div>
  )
}

const mapStateToProps = ({
  scrollOffset,
  isVideoPlaying,
  mainImageLoaded,
  isBannerInSight,
  isTouchScreen,
}) => {
  return {
    scrollOffset,
    isVideoPlaying,
    isBannerInSight,
    isTouchScreen,
    mainImageLoaded,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    bannerLoaded: () => dispatch({ type: `IMG_LOADED` }),
    updateIsVideoPlaying: bool =>
      dispatch({ type: "UPDATE_IS_PLAYING", payload: bool }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Video)
