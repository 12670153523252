import React, { useEffect, useRef } from "react"
import AcrSvg from "./AcrSvg"
import { connect } from "react-redux"
import { Trans } from "@lingui/macro"

const Footer = ({ isTouchScreen }) => {
  return (
    <footer
      style={{
        height: isTouchScreen ? "50vh" : "40vh",
        // marginTop:isTouchScreen?'-10vh':0
      }}
    >
      <div
        className="footer"
        style={{ height: isTouchScreen ? "50vh" : "40vh" }}
      >
        <div className="footer-container">
          <div className="row">
            <div className="col-sm-8 offset-sm-2 text-center">
              <p className="title">AU CHARME RABELAISIEN</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 offset-sm-1 col-lg-5 offset-lg-1 contacts">
              <div className="contact" style={{ marginLeft: 20 }}>
                <div className="title">
                  <Trans>Contact</Trans>
                </div>
                <p>info@hotel-acr.com</p>
                <p>+33 2 47 57 53 84</p>
              </div>
              <div className="contact">
                <div className="title">
                  <Trans>Address</Trans>
                </div>
                <p>25 rue rabelais</p>
                <p>37400 Amboise</p>
                <p>France</p>
              </div>
            </div>
            <div className="col-sm-5 col-lg-3 offset-lg-2 d-none d-sm-block">
              <div className="container-footer">
                <div className="logo-footer">
                  {/* <LogoCircle footer={true} color='rgba(255, 255, 255, 0.884)' /> */}
                  <AcrSvg color="rgba(255, 255, 255, 0.884)" />
                  {/* <div className="acr">
                                        <AcrSvg color='rgba(255, 255, 255, 0.884)'/>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

const mapStateToProps = ({ isTouchScreen }) => {
  return { isTouchScreen }
}

export default connect(mapStateToProps, null)(Footer)
