import React, { useEffect, useRef } from "react"
import gsap from "gsap"
import { Trans } from "@lingui/macro"
import TransitionLink from "gatsby-plugin-transition-link"
import { connect } from "react-redux"

const RoomBox = ({
  showTransitionBanner,
  hideTransitionBanner,
  resetOffset,
  isFromRoom,
  language,
}) => {
  let square = useRef(null)
  let arrow = useRef(null)
  let text = useRef(null)
  let roomsContainer = useRef(null)
  let arrowBody = useRef(null)
  let arrowHead = useRef(null)

  const rotateButton = () => {
    let tl = gsap.timeline()
    tl.to(square, {
      duration: 0.3,
      backgroundColor: "rgb(164,13,13)",
      rotation: 315,
    })
      .to(arrow, { duration: 0.3, x: 15, fill: "white", width: 14 }, "<")
      .to(arrowBody, { duration: 0.3, stroke: "white" }, "<")
      .to(arrowHead, { duration: 0.3, fill: "white" }, "<")
      .to(text, { duration: 0.3, x: 10 }, "<")
  }

  const cancelRotation = () => {
    let tl = gsap.timeline()
    tl.to(square, { duration: 0.3, backgroundColor: "white", rotation: 45 })
      .to(
        arrow,
        { duration: 0.3, x: 0, fill: "rgb(164,13,13)", y: 0, width: 25 },
        "<"
      )
      .to(arrowBody, { duration: 0.3, stroke: "rgb(164,13,13)" }, "<")
      .to(arrowHead, { duration: 0.3, fill: "rgb(164,13,13)" }, "<")
      .to(text, { duration: 0.3, x: 0 }, "<")
  }

  return (
    <div className="description-box bath">
      <div className="title">
        <h3>
          <Trans>Salles de bains</Trans>
        </h3>
        <h3>
          <Trans>Modernes</Trans>
        </h3>
        <div className="line-separation"></div>
      </div>
      <p className="text">
        <Trans>
          Exclusives, spacieuses et incroyablement bien équipées (baignoires et
          douches à jets hydromassants séparées), elles sont aussi accueillantes
          et agréables que les grandes serviettes moelleuses que vous y
          trouverez.
        </Trans>
      </p>
      <div
        style={{ width: "100%" }}
        onClick={() => {
          isFromRoom(false)
          showTransitionBanner()
        }}
      >
        <TransitionLink
          to={`/${language}/rooms`}
          exit={{
            delay: 2,
          }}
          entry={{
            trigger: ({ node, e, exit, entry }) => {
              hideTransitionBanner()
              resetOffset(true)
            },
          }}
        >
          <div
            className="room-link"
            onMouseEnter={rotateButton}
            onMouseLeave={cancelRotation}
          >
            {/*Svg square */}
            <svg
              className="square"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              xmlSpace="preserve"
              ref={el => (square = el)}
            >
              <path
                d="M501.333,0H10.667C4.779,0,0,4.779,0,10.667v490.667C0,507.221,4.779,512,10.667,512h490.667
                        c5.888,0,10.667-4.779,10.667-10.667V10.667C512,4.779,507.221,0,501.333,0z M490.667,490.667H21.333V21.333h469.333V490.667z"
              ></path>
            </svg>
            {/* right arrow svg */}
            <svg
              ref={el => (arrow = el)}
              className="arrow-svg"
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              width="30.5869"
              height="6.3018"
              viewBox="0 0 30.5869 6.3018"
            >
              <line
                ref={el => (arrowBody = el)}
                x1="26.588"
                y1="3.1512"
                y2="3.1512"
                style={{
                  fill: "none",
                  stroke: "#a62619",
                  strokeMiterlimit: 20,
                  strokeWidth: 1,
                }}
              />
              <path
                ref={el => (arrowHead = el)}
                d="M777.207,621.8782a20.0245,20.0245,0,0,1-5.6634-3.1513l1.14,3.1513-1.14,3.15A20.0077,20.0077,0,0,1,777.207,621.8782Z"
                transform="translate(-746.6202 -618.7269)"
                style={{ fill: "#a62619" }}
              />
            </svg>
            <p ref={el => (text = el)}>
              <Trans>VOIR LES CHAMBRES</Trans>
            </p>
          </div>
        </TransitionLink>
      </div>
    </div>
  )
}

const mapStateToProps = ({ language }) => {
  return { language }
}

const mapDispatchToProps = dispatch => {
  return {
    showTransitionBanner: () => dispatch({ type: `IMG_NOT_LOADED` }),
    hideTransitionBanner: () => dispatch({ type: `IMG_LOADED` }),
    resetOffset: bool => dispatch({ type: "RESET_OFFSET", payload: bool }),
    isFromRoom: bool => dispatch({ type: "IS_FROM_ROOM", payload: bool }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomBox)
