import React, { useEffect, useRef } from "react"
import { Image } from "../../gatsby-images/image"
import { Trans } from "@lingui/macro"
import { connect } from "react-redux"
import gsap from "gsap"
import RoomBoxView from "./RoomBoxView"
import RoomBoxBath from "./RoomBoxBath"
import RoomBoxGarden from "./RoomBoxGarden"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const Rooms = ({ scrolltriggerReady, isTouchScreen, loadingImgs }) => {
  let castleView = useRef(null)
  let viewDescription = useRef(null)
  let bathPhoto = useRef(null)
  let bathDescription = useRef(null)
  let terraceDescription = useRef(null)
  let terracePhoto = useRef(null)
  let isSafari

  if (typeof window !== `undefined`) {
    isSafari = /apple/i.test(navigator.vendor) ? true : false
  }

  //tweens
  let castleViewTween = useRef(null)
  let bathTween = useRef(null)
  let terraceTween = useRef(null)

  useEffect(() => {
    if (scrolltriggerReady && loadingImgs) {
      castleViewTween.current && castleViewTween.current.kill()
      bathTween.current && bathTween.current.kill()
      terraceTween.current && terraceTween.current.kill()

      const windowHeight = window.innerHeight
      gsap.set([viewDescription, bathDescription, terraceDescription], {
        yPercent: -20,
      })
      ScrollTrigger.getById("catsleview-0") &&
        ScrollTrigger.getById("catsleview-0").kill()
      castleViewTween.current = gsap.fromTo(
        castleView,
        { yPercent: 0 },
        {
          scrollTrigger: {
            id: "catsleview-0",
            trigger: castleView,
            ease: "linear",
            scrub: true,
            start: "top 100%",
            end: windowHeight ? `${windowHeight}px` : "bottom top",
            //events: onEnter onLeave onEnterBack onLeaveBack
            toggleActions: "play none reverse none",
            //options: play, pause, resume, reset, restart, complete, reverse,none
          },
          yPercent: -20,
        }
      )
      gsap.set(viewDescription, { yPercent: -25 })
      gsap.to(viewDescription, {
        yPercent: -5,
        scrollTrigger: {
          trigger: castleView,
          start: "top 100%",
          end: windowHeight ? `${windowHeight}px` : "bottom top",
          scrub: true,
        },
      })

      /////////////////////////
      ScrollTrigger.getById("bathPhoto") &&
        ScrollTrigger.getById("bathPhoto").kill()
      bathTween.current = gsap.fromTo(
        bathPhoto,
        { yPercent: 0 },
        {
          scrollTrigger: {
            id: "bathPhoto",
            trigger: bathPhoto,
            scrub: true,
            start: "top 100%",
            end: windowHeight ? `${windowHeight}px` : "bottom top",
            //events: onEnter onLeave onEnterBack onLeaveBack
            toggleActions: "play none reverse none",
            //options: play, pause, resume, reset, restart, complete, reverse,none
          },
          yPercent: -20,
        }
      )
      gsap.set(bathDescription, { yPercent: -25 })
      gsap.to(bathDescription, {
        yPercent: -5,
        scrollTrigger: {
          trigger: bathPhoto,
          start: "top 100%",
          end: windowHeight ? `${windowHeight}px` : "bottom top",
          scrub: true,
        },
      })

      /////////////////////////////////
      ScrollTrigger.getById("terracePhoto") &&
        ScrollTrigger.getById("terracePhoto").kill()
      terraceTween.current = gsap.fromTo(
        terracePhoto,
        { yPercent: 0 },
        {
          scrollTrigger: {
            id: "terracePhoto",
            trigger: terracePhoto,
            scrub: true,
            start: "top 100%",
            end: windowHeight ? `${windowHeight}px` : "bottom top",
            //events: onEnter onLeave onEnterBack onLeaveBack
            toggleActions: "play none play reverse",
            //options: play, pause, resume, reset, restart, complete, reverse,none
          },
          yPercent: -20,
        }
      )
      gsap.set(terraceDescription, { yPercent: -25 })
      gsap.to(terraceDescription, {
        yPercent: -5,
        scrollTrigger: {
          trigger: terracePhoto,
          start: "top 100%",
          end: windowHeight ? `${windowHeight}px` : "bottom top",
          scrub: true,
        },
      })
    }
  }, [scrolltriggerReady, loadingImgs])

  useEffect(() => {
    return () => {
      castleViewTween.current && castleViewTween.current.kill()
      bathTween.current && bathTween.current.kill()
      terraceTween.current && terraceTween.current.kill()
    }
  }, [])

  return (
    <div style={{ display: "flex" }} className="row">
      <div className="homepage-rooms col-xs-12 col-sm-10">
        <div className="offset-1">
          <div className="title">
            <Trans>DÉCROCHER</Trans>
          </div>
          <h2>
            <Trans>Chambres</Trans>
          </h2>
          <h2>
            <Trans>avec Vue</Trans>
          </h2>
        </div>
        <div className="description">
          <div style={{ minWidth: 50 }} className="offset-line col-1"></div>
          <div className="text">
            <p className="col-5">
              <Trans>
                Célébrant l'esprit d'Amboise et de sa Loire sauvage, les 10
                chambres de Au Charme Rabelaisien entourent les visiteurs de la
                beauté captivante de la destination.
              </Trans>
            </p>
            <p className="col-5" style={{ marginLeft: 15 }}>
              <Trans>
                Une invitation à profiter des vues les plus extraordinaires sur
                le château, le jardin paysagé ou les superbes toits de cette
                ville historique.
              </Trans>
            </p>
          </div>
        </div>
        <div className="room-type">
          <div className="photo castle row">
            <div className="img">
              <div
                className="image-overflow"
                style={{
                  overflow: isSafari && !isTouchScreen ? "visible" : "hidden",
                }}
              >
                <div
                  ref={el => (castleView = el)}
                  style={{ zIndex: 0, position: "relative" }}
                >
                  {loadingImgs && <Image src="castle-view" />}
                </div>
              </div>
              <div
                className="description"
                style={{ marginTop: 0 }}
                ref={el => (viewDescription = el)}
              >
                <RoomBoxView />
              </div>
            </div>
          </div>
          <div className="photo bath row">
            <div className="img">
              <div
                className="image-overflow"
                style={{
                  overflow: isSafari && !isTouchScreen ? "visible" : "hidden",
                }}
              >
                <div
                  ref={el => (bathPhoto = el)}
                  style={{ zIndex: 0, position: "relative" }}
                >
                  {loadingImgs && <Image src="main-bathroom-room-5" />}
                </div>
              </div>
              <div
                className="description"
                style={{ marginTop: 0 }}
                ref={el => (bathDescription = el)}
              >
                <RoomBoxBath />
              </div>
            </div>
          </div>
          <div className="photo terraces row">
            <div className="img">
              <div
                className="image-overflow"
                style={{
                  overflow: isSafari && !isTouchScreen ? "visible" : "hidden",
                }}
              >
                <div
                  ref={el => (terracePhoto = el)}
                  style={{ zIndex: 0, position: "relative" }}
                >
                  {loadingImgs && <Image src="garden-rose" />}
                </div>
              </div>
              <div
                className="description"
                style={{ marginTop: 0 }}
                ref={el => (terraceDescription = el)}
              >
                <RoomBoxGarden />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  scrolltriggerReady,
  isTouchScreen,
  windowWidth,
}) => {
  return { scrolltriggerReady, isTouchScreen, windowWidth }
}

export default connect(mapStateToProps, null)(Rooms)
