import React, { useEffect, useRef } from "react"
import gsap from "gsap"
import { connect } from "react-redux"

const BannerSvg = ({ language }) => {
  let rotateEl = useRef()
  useEffect(() => {
    var rotateDuration = 30

    var rotate = gsap.to(rotateEl, {
      rotation: 360,
      duration: rotateDuration,
      onReverseComplete() {
        this.totalTime(rotateDuration * 100) // loop in reverse
      },
      repeat: -1,
      ease: "none",
    })
  }, [])
  return (
    <div className="svg-bienvenue-container">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        // width="494.1636"
        // height="494.1875"
        width="150"
        height="150"
        viewBox="0 0 494.1636 494.1875"
        ref={el => (rotateEl = el)}
      >
        {language == "fr" && (
          <>
            <text
              transform="translate(246.7495 39.7681) rotate(3.4512)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              B
            </text>
            <text
              transform="matrix(0.9731, 0.2302, -0.2302, 0.9731, 289.3267, 44.4048)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              I
            </text>
            <text
              transform="translate(316.7339 51.8257) rotate(22.9213)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              E
            </text>
            <text
              transform="translate(353.1958 68.7139) rotate(35.1602)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              N
            </text>
            <text
              transform="translate(391.271 98.0801) rotate(47.7433)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              V
            </text>
            <text
              transform="translate(419.0835 131.4175) rotate(59.2895)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              E
            </text>
            <text
              transform="translate(438.4292 166.6118) rotate(71.5588)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              N
            </text>
            <text
              transform="translate(451.7554 212.7383) rotate(84.8292)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              U
            </text>
            <text
              transform="translate(453.9272 260.2871) rotate(96.8434)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              E
            </text>
            <text
              transform="translate(447.1831 300.2354) rotate(106.2551)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            ></text>
            <text
              transform="translate(438.9897 326.0137) rotate(116.4575)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              A
            </text>
            <text
              transform="translate(417.7427 365.1621) rotate(129.0022)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              U
            </text>
            <text
              transform="translate(385.7983 400.7217) rotate(139.3026)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            ></text>
            <text
              transform="matrix(-0.859, 0.512, -0.512, -0.859, 364.8335, 417.8379)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              C
            </text>
            <text
              transform="matrix(-0.9511, 0.3088, -0.3088, -0.9511, 325.9224, 439.1113)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              H
            </text>
            <text
              transform="translate(279.9526 451.9473) rotate(175.0096)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              A
            </text>
            <text
              transform="translate(234.0679 454.0156) rotate(-172.9023)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              R
            </text>
            <text
              transform="translate(192.2915 447.6211) rotate(-159.3825)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              M
            </text>
            <text
              transform="matrix(-0.8308, -0.5565, 0.5565, -0.8308, 141.8794, 425.7051)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              E
            </text>
            <text
              transform="translate(109.3281 401.584) rotate(-136.8569)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            ></text>
            <text
              transform="matrix(-0.6053, -0.796, 0.796, -0.6053, 90.0908, 382.5107)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              R
            </text>
            <text
              transform="translate(65.5889 347.6367) rotate(-114.9631)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              A
            </text>
            <text
              transform="matrix(-0.2186, -0.9758, 0.9758, -0.2186, 48.0098, 305.2256)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              B
            </text>
            <text
              transform="matrix(-0.0206, -0.9998, 0.9998, -0.0206, 40.4482, 263.2402)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              E
            </text>
            <text
              transform="translate(41.2427 222.9438) rotate(-80.2435)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              L
            </text>
            <text
              transform="translate(49.2441 184.5073) rotate(-68.3694)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              A
            </text>
            <text
              transform="translate(68.3887 142.5376) rotate(-58.0254)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              I
            </text>
            <text
              transform="translate(84.187 118.9355) rotate(-48.5124)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              S
            </text>
            <text
              transform="translate(112.4478 89.853) rotate(-39.0184)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              I
            </text>
            <text
              transform="translate(135.064 72.6841) rotate(-29.4988)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              E
            </text>
            <text
              transform="translate(170.6938 54.0757) rotate(-17.2108)"
              style={{
                fontSize: 48,
                fill: "#fff",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              N
            </text>
          </>
        )}
        {language == "ru" && (
          <>
            <text
              transform="matrix(0.9986, 0.0528, -0.0528, 0.9986, 236.8247, 33.2622)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              Д
            </text>
            <text
              transform="translate(271.7324 36.0845) rotate(13.0096)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              О
            </text>
            <text
              transform="matrix(0.9223, 0.3866, -0.3866, 0.9223, 307.9092, 45.7705)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              Б
            </text>
            <text
              transform="matrix(0.8513, 0.5247, -0.5247, 0.8513, 337.3047, 59.1831)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              Р
            </text>
            <text
              transform="translate(363.7646 76.4292) rotate(41.1354)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              О
            </text>
            <text
              transform="matrix(0.6543, 0.7562, -0.7562, 0.6543, 391.0898, 102.2783)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            ></text>
            <text
              transform="matrix(0.546, 0.8378, -0.8378, 0.546, 404.2422, 118.0181)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              П
            </text>
            <text
              transform="translate(422.8242 148.4194) rotate(67.0405)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              О
            </text>
            <text
              transform="translate(436.3008 182.6455) rotate(77.8526)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              Ж
            </text>
            <text
              transform="translate(443.5801 223.5986) rotate(88.4014)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              А
            </text>
            <text
              transform="translate(443.3838 259.5107) rotate(98.1523)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              Л
            </text>
            <text
              transform="translate(437.6143 293.0742) rotate(108.109)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              О
            </text>
            <text
              transform="matrix(-0.4665, 0.8845, -0.8845, -0.4665, 424.7451, 328.251)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              В
            </text>
            <text
              transform="translate(408.8936 356.3145) rotate(127.0702)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              А
            </text>
            <text
              transform="matrix(-0.7151, 0.699, -0.699, -0.7151, 388.0771, 382.2178)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              Т
            </text>
            <text
              transform="matrix(-0.8124, 0.583, -0.583, -0.8124, 365.5283, 402.999)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              Ь
            </text>
            <text
              transform="translate(338.418 420.9639) rotate(151.6827)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            ></text>
            <text
              transform="translate(320.1162 430.3164) rotate(159.0523)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              В
            </text>
            <text
              transform="matrix(-0.9721, 0.2345, -0.2345, -0.9721, 289.457, 440.7559)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            ></text>
            <text
              transform="translate(269.4063 445.1953) rotate(174.1331)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              A
            </text>
            <text
              transform="matrix(-0.9981, -0.0614, 0.0614, -0.9981, 235.7725, 447.7334)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              U
            </text>
            <text
              transform="translate(199.8354 444.1133) rotate(-168.6484)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            ></text>
            <text
              transform="translate(179.7856 439.665) rotate(-161.0773)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              C
            </text>
            <text
              transform="translate(148.3726 427.874) rotate(-151.3795)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              H
            </text>
            <text
              transform="translate(117.2256 409.5703) rotate(-141.5917)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              A
            </text>
            <text
              transform="translate(90.6934 387.0908) rotate(-132.3845)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              R
            </text>
            <text
              transform="translate(69.627 362.9785) rotate(-122.2441)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              M
            </text>
            <text
              transform="translate(48.8149 327.0117) rotate(-112.2569)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              E
            </text>
            <text
              transform="translate(38.2534 298.2461) rotate(-105.1089)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            ></text>
            <text
              transform="translate(33.3384 278.2988) rotate(-97.7634)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              R
            </text>
            <text
              transform="translate(29.9131 246.2051) rotate(-88.5603)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              A
            </text>
            <text
              transform="translate(31.9019 211.5225) rotate(-79.3276)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              B
            </text>
            <text
              transform="matrix(0.3326, -0.9431, 0.9431, 0.3326, 38.9043, 180.0049)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              E
            </text>
            <text
              transform="translate(49.9512 151.5806) rotate(-62.1827)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              L
            </text>
            <text
              transform="matrix(0.5982, -0.8013, 0.8013, 0.5982, 64.5366, 125.6533)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              A
            </text>
            <text
              transform="translate(86.458 98.5093) rotate(-45.4909)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              I
            </text>
            <text
              transform="translate(101.9194 83.5273) rotate(-38.307)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              S
            </text>
            <text
              transform="translate(126.6323 65.4043) rotate(-31.0998)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              I
            </text>
            <text
              transform="translate(145.3506 54.7305) rotate(-23.8528)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              E
            </text>
            <text
              transform="matrix(0.968, -0.251, 0.251, 0.968, 173.5, 43.1504)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              N
            </text>
            <text
              transform="matrix(0.9933, -0.1152, 0.1152, 0.9933, 209.1699, 35.3945)"
              style={{
                fontSize: 36,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            ></text>
          </>
        )}
        {language == "en" && (
          <>
            <text
              transform="translate(244.9028 37.5942) rotate(5.1657)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              W
            </text>
            <text
              transform="translate(298.9512 45.1675) rotate(18.0869)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              E
            </text>
            <text
              transform="matrix(0.8769, 0.4807, -0.4807, 0.8769, 335.2109, 58.6279)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              L
            </text>
            <text
              transform="matrix(0.7733, 0.6341, -0.6341, 0.7733, 366.209, 76.8711)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              C
            </text>
            <text
              transform="translate(397.5107 104.2349) rotate(51.4737)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              O
            </text>
            <text
              transform="translate(425.7744 142.249) rotate(65.2991)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              M
            </text>
            <text
              transform="translate(445.4688 191.1514) rotate(78.0091)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              E
            </text>
            <text
              transform="translate(451.7871 229.4893) rotate(86.9849)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            ></text>
            <text
              transform="matrix(-0.0938, 0.9956, -0.9956, -0.0938, 452.4854, 253.6865)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              T
            </text>
            <text
              transform="matrix(-0.293, 0.9561, -0.9561, -0.293, 447.9287, 290.9844)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              O
            </text>
            <text
              transform="translate(431.6475 335.8457) rotate(117.2958)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            ></text>
            <text
              transform="matrix(-0.602, 0.7985, -0.7985, -0.602, 419.2227, 358.623)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              A
            </text>
            <text
              transform="matrix(-0.7546, 0.6562, -0.6562, -0.7546, 392.3506, 391.8662)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              U
            </text>
            <text
              transform="translate(356.3164 420.2109) rotate(148.9004)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            ></text>
            <text
              transform="translate(333.7666 433.0625) rotate(158.4576)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              C
            </text>
            <text
              transform="translate(293.6895 447.2412) rotate(170.761)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              H
            </text>
            <text
              transform="matrix(-0.9986, -0.0536, 0.0536, -0.9986, 248.2002, 452.7949)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              A
            </text>
            <text
              transform="translate(204.3657 448.6523) rotate(-165.1978)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              R
            </text>
            <text
              transform="translate(165.4512 437.2246) rotate(-152.2152)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              M
            </text>
            <text
              transform="translate(120.0596 410.4521) rotate(-139.6231)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              E
            </text>
            <text
              transform="translate(91.6196 383.9453) rotate(-130.686)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            ></text>
            <text
              transform="matrix(-0.5217, -0.8531, 0.8531, -0.5217, 75.2056, 363.7734)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              R
            </text>
            <text
              transform="translate(55.1655 328.1504) rotate(-109.6134)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              A
            </text>
            <text
              transform="translate(42.1079 286.125) rotate(-97.8027)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              B
            </text>
            <text
              transform="translate(38.1846 245.3687) rotate(-86.9056)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              E
            </text>
            <text
              transform="translate(41.7773 206.8955) rotate(-76.3305)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              L
            </text>
            <text
              transform="translate(51.9189 170.5869) rotate(-64.9278)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              A
            </text>
            <text
              transform="translate(72.5737 131.4746) rotate(-55.0332)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              I
            </text>
            <text
              transform="translate(88.8677 109.6265) rotate(-45.9179)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              S
            </text>
            <text
              transform="translate(117.144 82.9458) rotate(-36.8125)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              I
            </text>
            <text
              transform="translate(139.4497 67.2891) rotate(-27.6653)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              E
            </text>
            <text
              transform="translate(174.1313 50.5093) rotate(-15.8772)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            >
              N
            </text>
            <text
              transform="matrix(0.9948, -0.1016, 0.1016, 0.9948, 219.2827, 40.0732)"
              style={{
                fontSize: 46,
                fill: "white",
                fontFamily: "MyriadPro-Regular, Myriad Pro",
              }}
            ></text>
          </>
        )}
      </svg>
      <svg
        id="play"
        className="play"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        // width="494.1636"
        // height="494.1875"
        width="150"
        height="150"
        viewBox="0 0 494.1636 494.1875"
      >
        <polygon
          points="297.083 247.094 222.083 203.792 222.083 290.395 297.083 247.094"
          style={{ fill: "#fff" }}
        />
      </svg>

      {/* <svg 
                id="Layer_1" 
                data-name="Layer 1" 
                xmlns="http://www.w3.org/2000/svg" 
                width="494.1636" 
                height="494.1875" 
                viewBox="0 0 494.1636 494.1875">
                    
                
                <text 
                        
                        transform="translate(246.7495 39.7681) rotate(3.4512)" 
                        
                            style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}
                        }>
                            B
                        </text>
                        
                        
                        <text 
                            transform="matrix(0.9731, 0.2302, -0.2302, 0.9731, 289.3267, 44.4048)" 
                            
                                style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            I
                        </text>
                        <text 
                            transform="translate(316.7339 51.8257) rotate(22.9213)" 
                            style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            E
                        </text>
                        <text 
                            transform="translate(353.1958 68.7139) rotate(35.1602)" 
                            style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            N
                        </text>
                        <text 
                            transform="translate(391.271 98.0801) rotate(47.7433)" 
                            style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            V
                        </text>
                        <text 
                            transform="translate(419.0835 131.4175) rotate(59.2895)" 
                            style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            E
                        </text>
                        <text 
                            transform="translate(438.4292 166.6118) rotate(71.5588)" 
                            style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            N
                        </text>
                        <text 
                            transform="translate(451.7554 212.7383) rotate(84.8292)" 
                            style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            U
                        </text>
                        <text 
                            transform="translate(453.9272 260.2871) rotate(96.8434)" 
                            style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            E
                        </text>
                        <text 
                            transform="translate(447.1831 300.2354) rotate(106.2551)" 
                            style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            </text>
                        <text 
                            transform="translate(438.9897 326.0137) rotate(116.4575)" 
                            style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            A
                        </text>
                        <text 
                        transform="translate(417.7427 365.1621) rotate(129.0022)" 
                        style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            U
                        </text><text 
                        
                        transform="translate(385.7983 400.7217) rotate(139.3026)" 
                        style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                             
                            </text><text 
                        transform="matrix(-0.859, 0.512, -0.512, -0.859, 364.8335, 417.8379)" 
                        style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            C
                        </text><text 
                        transform="matrix(-0.9511, 0.3088, -0.3088, -0.9511, 325.9224, 439.1113)" 
                        style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            H
                        </text><text 
                        transform="translate(279.9526 451.9473) rotate(175.0096)" 
                        style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            A
                        </text><text 
                        transform="translate(234.0679 454.0156) rotate(-172.9023)" 
                        style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            R
                        </text><text 
                        transform="translate(192.2915 447.6211) rotate(-159.3825)" 
                        style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            M
                        </text><text 
                        transform="matrix(-0.8308, -0.5565, 0.5565, -0.8308, 141.8794, 425.7051)" 
                        style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            E
                        </text><text 
                        transform="translate(109.3281 401.584) rotate(-136.8569)" 
                        style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                             
                            </text><text 
                        transform="matrix(-0.6053, -0.796, 0.796, -0.6053, 90.0908, 382.5107)" 
                        style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            R
                        </text><text 
                        transform="translate(65.5889 347.6367) rotate(-114.9631)" 
                        style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            A
                        </text><text 
                        transform="matrix(-0.2186, -0.9758, 0.9758, -0.2186, 48.0098, 305.2256)" 
                        style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            B
                        </text><text 
                        transform="matrix(-0.0206, -0.9998, 0.9998, -0.0206, 40.4482, 263.2402)" 
                        style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            E
                        </text><text 
                        transform="translate(41.2427 222.9438) rotate(-80.2435)" 
                        style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            L
                        </text><text 
                        transform="translate(49.2441 184.5073) rotate(-68.3694)" 
                        style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            A
                        </text><text 
                        transform="translate(68.3887 142.5376) rotate(-58.0254)" 
                        style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            I
                        </text><text 
                        transform="translate(84.187 118.9355) rotate(-48.5124)" 
                        style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            S
                        </text><text 
                        transform="translate(112.4478 89.853) rotate(-39.0184)" 
                        style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            I
                        </text><text 
                        transform="translate(135.064 72.6841) rotate(-29.4988)" 
                        style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            E
                        </text><text 
                        transform="translate(170.6938 54.0757) rotate(-17.2108)" 
                        style={{fontSize:48 ,fill: "#fff", fontFamily: "MyriadPro-Regular, Myriad Pro"}}>
                            N
                        </text>
                    </svg> */}
    </div>
  )
}

const mapStateToProps = ({ language }) => {
  return { language }
}

export default connect(mapStateToProps, null)(BannerSvg)
