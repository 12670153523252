import React, { useState, useRef, useEffect } from "react"
import AwesomeSlider from "react-awesome-slider"
import styles from "react-awesome-slider/src/styles"
import AwesomeSliderStyles from "react-awesome-slider/src/styled/scale-out-animation/scale-out-animation.scss"
import gsap from "gsap"
import { connect } from "react-redux"
import { Trans } from "@lingui/macro"
import { Image } from "../../gatsby-images/image"

const Gallery = ({
  isTouchScreen,
  updateShowCursors,
  showGalleryCursors,
  scrollOffset,
  loadingImgs,
}) => {
  const [slideNumber, setSlideNumber] = useState(0)
  const [totalSlides, setTotalSlides] = useState(1)
  const [mouseInside, setMouseInside] = useState("")

  let slider = useRef()
  let sliderContainer = useRef()
  let rightButton = useRef()
  let leftButton = useRef()
  let smallSliders = useRef([])

  const [cursorAnimation, setCursorAnimation] = useState(false)

  const addToSmallSliders = el => {
    if (el && !smallSliders.current.includes(el)) {
      smallSliders.current.push(el)
    }
  }

  let changeSlideNext = e => {
    showGalleryCursors && updateShowCursors(false)
    leftButton.click()
  }

  let changeSlidePrev = e => {
    rightButton.click()
    showGalleryCursors && updateShowCursors(false)
  }

  let hideCircle = () => {
    gsap.to(slider, {
      duration: 0.5,
      ease: "power2.out",
      opacity: 0,
    })
  }
  let showCircle = () => {
    gsap.to(slider, {
      duration: 0.5,
      ease: "power2.out",
      opacity: 1,
    })
  }
  useEffect(() => {
    //if devise is touchscreen have cursors appear
    isTouchScreen && updateShowCursors(true)
  }, [isTouchScreen])

  useEffect(() => {
    const smallSlidersConst = smallSliders.current
    let animation
    if (showGalleryCursors) {
      gsap.set(smallSlidersConst[0], { rotate: -180 })
      gsap.set(smallSlidersConst, { visibility: "visible" })
      animation = gsap.fromTo(
        smallSlidersConst,
        { scale: 0.5, opacity: 0.1 },
        {
          ease: "power2.out",
          scale: 1,
          duration: 1,
          opacity: 0.9,
          repeat: -1,
          yoyo: true,
          delay: 1,
        }
      )
      setCursorAnimation(true)
    } else if (!showGalleryCursors && cursorAnimation) {
      //cursors have been clicked and are now hidden
      gsap.to(smallSlidersConst, { visibility: "hidden" })
    }
  }, [isTouchScreen, showGalleryCursors])

  let flipCursor = side => {
    if (mouseInside === side) {
      return
    } else if (mouseInside === "right") {
      gsap.to(slider, { rotation: 180 })
      setMouseInside("left")
    } else if ((mouseInside === "") & (side === "left")) {
      gsap.to(slider, { rotation: 180 })
      setMouseInside("left")
    } else if ((mouseInside === "left") & (side === "right")) {
      gsap.to(slider, { rotation: 0 })
      setMouseInside("right")
    } else if ((mouseInside === "right") & (side === "left")) {
      gsap.to(slider, { rotation: 180 })
      setMouseInside("left")
    } else {
      setMouseInside(side)
    }
  }
  let moveCircle = e => {
    gsap.to(slider, {
      duration: 0.8,
      ease: "power2.out",
      css: {
        left: e.pageX,
        top: e.screenY - 50 + scrollOffset,
      },
    })
  }

  return (
    <div className="gallery-container">
      <div ref={e => (slider = e)} className="ball"></div>
      <div className="row">
        <div className="col-10 offset-2">
          <div className="section-title">
            <Trans>GALLERIE</Trans>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-8 offset-2">
          <div className="main-title">
            <Trans>Un avant-goût de notre caractêre</Trans>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-2">
          <div className="slide-counter">
            <div className="current-number">{slideNumber + 1}</div>
            <div className="total-slides">/{totalSlides}</div>
          </div>
        </div>
        <div
          className="col-sm-10"
          style={{ position: "relative" }}
          onMouseLeave={() => hideCircle()}
          onMouseEnter={() => !isTouchScreen && showCircle()}
        >
          <div
            className="ball small-left"
            ref={el => addToSmallSliders(el)}
          ></div>
          <div
            className="ball small-right"
            ref={el => addToSmallSliders(el)}
          ></div>
          <div
            className="left-slider"
            ref={sliderContainer}
            onMouseEnter={e => {
              flipCursor("left")
            }}
            onClick={e => changeSlideNext(e)}
            onMouseMove={e => {
              moveCircle(e)
            }}
            style={{
              width: "50%",
              cursor: "none",
              zIndex: 100,
              height: "100%",
              zIndex: 100,
              position: "absolute",
            }}
          ></div>
          <div
            className="right-slider"
            ref={sliderContainer}
            onMouseEnter={e => {
              flipCursor("right")
            }}
            onClick={e => changeSlidePrev(e)}
            onMouseMove={e => {
              moveCircle(e)
            }}
            style={{
              width: "50%",
              cursor: "none",
              zIndex: 100,
              height: "100%",
              zIndex: 100,
              position: "absolute",
              marginLeft: "50%",
            }}
          ></div>
          <div className="gallery">
            <AwesomeSlider
              organicArrows={false}
              animation="scaleOutAnimation"
              cssModule={AwesomeSliderStyles}
              mobileTouch={true}
              buttonContentLeft={<div ref={el => (leftButton = el)} />}
              buttonContentRight={<div ref={el => (rightButton = el)} />}
              onTransitionEnd={e => setSlideNumber(e.currentIndex)}
              onFirstMount={e => {
                setTotalSlides(e.slides)
              }}
              bullets={false}
            >
              <div style={{ width: "100%" }}>
                {loadingImgs && <Image src="rose-garden-facade" />}
              </div>
              <div style={{ width: "100%" }}>
                {loadingImgs && <Image src="main-image-room-photos-8" />}
              </div>
              <div style={{ width: "100%" }}>
                {loadingImgs && <Image src="curtains-lobby" />}
              </div>
              <div style={{ width: "100%" }}>
                {loadingImgs && <Image src="piscine-landscape" />}
              </div>
              <div style={{ width: "100%" }}>
                {loadingImgs && <Image src="mirror-lobby" />}
              </div>
            </AwesomeSlider>
          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = ({
  isTouchScreen,
  showGalleryCursors,
  scrollOffset,
}) => {
  return { isTouchScreen, showGalleryCursors, scrollOffset }
}

const mapDispatchToProps = dispatch => {
  return {
    updateShowCursors: bool =>
      dispatch({ type: `UPDATE_SHOW_GALLERY_CURSORS`, payload: bool }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Gallery)
