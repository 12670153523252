import React, { useState, useRef, useEffect } from "react"
import AwesomeSlider from "react-awesome-slider"
import styles from "react-awesome-slider/src/styles"
import AwesomeSliderStyles from "react-awesome-slider/src/styled/scale-out-animation/scale-out-animation.scss"
import gsap from "gsap"
import { connect } from "react-redux"
import { Trans } from "@lingui/macro"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Image } from "../gatsby-images/image"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { console, setTimeout } from "globalthis/implementation"

const responsive = {
  desktop: {
    breakpoint: { max: 5000, min: 1024 },
    items: 1,
    partialVisibilityGutter: 50,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    partialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 10,
  },
}

const FancyButton = React.forwardRef(({ onClick }, ref) => (
  <div ref={ref} onClick={() => onClick()} />
))

const Services = ({
  isTouchScreen,
  updateShowCursors,
  showServicesCursors,
  scrollOffset,
  scrolltriggerReady,
  windowWidth,
}) => {
  const [slideNumber, setSlideNumber] = useState(0)
  const [totalSlides, setTotalSlides] = useState(1)
  const [mouseInside, setMouseInside] = useState("")

  let slider = useRef()
  let sliderContainer = useRef()
  let rightButton = useRef()
  let leftButton = useRef()
  let smallSliders = useRef([])
  let descriptionsBoxes = useRef([])
  let images = useRef([])
  let container = useRef(null)

  //tweens
  let imagesTween = useRef(null)

  const [isSafari, setIsSafari] = useState(false)

  // if (typeof window !== `undefined`) {
  //     isSafari = /apple/i.test(navigator.vendor)?true:false
  // }

  const [cursorAnimation, setCursorAnimation] = useState(false)
  const [moveBoxes, setMoveBoxes] = useState(false)

  const [count, setCount] = useState(1)

  const addToSmallSliders = el => {
    if (el && !smallSliders.current.includes(el)) {
      smallSliders.current.push(el)
    }
  }

  const addToBoxesSliders = el => {
    if (el && !descriptionsBoxes.current.includes(el)) {
      // debugger
      descriptionsBoxes.current.push(el)
    }
  }

  const addToImages = el => {
    if (el && !images.current.includes(el)) {
      images.current.push(el)
    }
  }

  let changeSlideNext = e => {
    showServicesCursors && updateShowCursors(false)
    leftButton && leftButton.click()
    setMoveBoxes(true)
    setTimeout(() => setMoveBoxes(false), 2000)
    count == "1" ? setCount(5) : setCount(count - 1)
  }

  let changeSlidePrev = e => {
    rightButton.click()
    setMoveBoxes(true)
    setTimeout(() => setMoveBoxes(false), 2000)
    showServicesCursors && updateShowCursors(false)
    count == "5" ? setCount(1) : setCount(count + 1)
  }

  let hideCircle = () => {
    gsap.to(slider, {
      duration: 0.5,
      ease: "power2.out",
      opacity: 0,
    })
  }
  let showCircle = () => {
    gsap.to(slider, {
      duration: 0.5,
      ease: "power2.out",
      opacity: 1,
    })
  }
  useEffect(() => {
    //if devise is touchscreen have cursors appear
    updateShowCursors(true)
  }, [isTouchScreen])

  useEffect(() => {
    if (moveBoxes && windowWidth > 576) {
      let boxes = descriptionsBoxes.current
      gsap.to(boxes, { xPercent: -20, duration: 1 })
      gsap.to(boxes, { xPercent: 0, duration: 1, delay: 1 })
    }
  }, [moveBoxes])

  // useEffect(() => {
  //     if(scrolltriggerReady) {
  //         setTimeout(() => {
  //             const boxSliderConst = images.current
  //             navigator.userAgent.toLowerCase().indexOf('safari/') > -1 && setIsSafari(true)
  //             gsap.set(boxSliderConst, {y: windowWidth < 576?300:300})
  //             console.log(boxSliderConst, 'boxSliderConst')
  //             ScrollTrigger.getById('image-service') && ScrollTrigger.getById('image-service').kill()
  //                 imagesTween.current = gsap.to(boxSliderConst, {
  //                     y: 0,
  //                     scrollTrigger: {
  //                         trigger: container,
  //                         id: 'image-service',
  //                         start: windowWidth<576?'top 50%':'top bottom',
  //                         end: windowWidth<576?'bottom 50%': 'bottom top',
  //                         scrub: true
  //                     }
  //                 })
  //         }

  //         ,1000)
  //     }

  //     if(scrolltriggerReady) {
  //         // setTimeout(() => {
  //             const boxSliderConst = descriptionsBoxes.current[2]
  //             navigator.userAgent.toLowerCase().indexOf('safari/') > -1 && setIsSafari(true)
  //             gsap.set(boxSliderConst, {y: windowWidth < 576?300:300})
  //             console.log(boxSliderConst, 'boxSliderConst')
  //             ScrollTrigger.getById('image-service') && ScrollTrigger.getById('image-service').kill()
  //                 imagesTween.current = gsap.to(boxSliderConst, {
  //                     y: 0,
  //                     scrollTrigger: {
  //                         trigger: container,
  //                         id: 'image-service',
  //                         start: windowWidth<576?'top 50%':'top bottom',
  //                         end: windowWidth<576?'bottom 50%': 'bottom top',
  //                         scrub: true
  //                     }
  //                 })

  //     }

  useEffect(() => {
    return () => {
      descriptionsBoxes.current = []
      imagesTween.current && imagesTween.current.kill()
    }
  }, [])
  useEffect(() => {
    const smallSlidersConst = smallSliders.current
    let animation
    if (isTouchScreen && showServicesCursors) {
      gsap.set(smallSlidersConst[0], { rotate: -180 })
      gsap.set(smallSlidersConst, { visibility: "visible" })
      animation = gsap.fromTo(
        smallSlidersConst,
        { scale: 0.5, opacity: 0.1 },
        {
          ease: "power2.out",
          scale: 1,
          duration: 1,
          opacity: 0.9,
          repeat: -1,
          yoyo: true,
        }
      )
      setCursorAnimation(true)
    } else if (!showServicesCursors && cursorAnimation) {
      //cursors have been clicked and are now hidden
      gsap.to(smallSlidersConst, { visibility: "hidden" })
    }
  }, [isTouchScreen, showServicesCursors])

  let flipCursor = side => {
    if (mouseInside === side) {
      return
    } else if (mouseInside === "right") {
      gsap.to(slider, { rotation: 180 })
      setMouseInside("left")
    } else if ((mouseInside === "") & (side === "left")) {
      gsap.to(slider, { rotation: 180 })
      setMouseInside("left")
    } else if ((mouseInside === "left") & (side === "right")) {
      gsap.to(slider, { rotation: 0 })
      setMouseInside("right")
    } else if ((mouseInside === "right") & (side === "left")) {
      gsap.to(slider, { rotation: 180 })
      setMouseInside("left")
    } else {
      setMouseInside(side)
    }
  }
  let moveCircle = e => {
    gsap.to(slider, {
      duration: 0.8,
      ease: "power2.out",
      css: {
        left: e.pageX,
        top: e.screenY - 50 + scrollOffset,
      },
    })
  }

  return (
    <div className="gallery-container services" ref={el => (container = el)}>
      <div ref={e => (slider = e)} className="ball"></div>
      <div className="row">
        <div className="col-sm-2">
          <div className="slide-counter">
            <div className="current-number">{count}</div>
            <div className="total-slides">/{5}</div>
          </div>
        </div>
        <div
          className="col-sm-10"
          style={{ position: "relative" }}
          onMouseLeave={() => hideCircle()}
          onMouseEnter={() => !isTouchScreen && showCircle()}
        >
          <div
            className="ball small-left"
            ref={el => addToSmallSliders(el)}
          ></div>
          <div
            className="ball small-right"
            ref={el => addToSmallSliders(el)}
          ></div>
          <div
            className="left-slider"
            ref={sliderContainer}
            onMouseEnter={e => {
              flipCursor("left")
            }}
            onClick={e => changeSlideNext(e)}
            onMouseMove={e => {
              moveCircle(e)
            }}
            style={{
              width: "50%",
              cursor: "none",
              zIndex: 100,
              height: "100%",
              zIndex: 100,
              position: "absolute",
            }}
          ></div>
          <div
            className="right-slider"
            ref={sliderContainer}
            onMouseEnter={e => {
              flipCursor("right")
            }}
            onClick={e => changeSlidePrev(e)}
            onMouseMove={e => {
              moveCircle(e)
            }}
            style={{
              width: "50%",
              cursor: "none",
              zIndex: 100,
              height: "100%",
              zIndex: 100,
              position: "absolute",
              marginLeft: "50%",
            }}
          ></div>
          <div
            className="services-area"
            // ref={addToImages}
          >
            {windowWidth > 800 && (
              <div
              // style={{display: windowWidth > 800?'block':'none'}}
              >
                <Carousel
                  // ssr
                  partialVisible
                  // deviceType={deviceType}
                  itemClass="image-item"
                  // centerMode={true}
                  responsive={responsive}
                  infinite={true}
                  customRightArrow={
                    <FancyButton ref={el => (rightButton = el)} />
                  }
                  customLeftArrow={
                    <FancyButton ref={el => (leftButton = el)} />
                  }
                  customTransition={"transform 900ms ease-in-out"}
                  // afterChange={e => console.log(e, 'e')}
                >
                  <div className="services-area" style={{ width: "100%" }}>
                    <div className="image-container">
                      <div
                        ref={addToImages}
                        style={{ zIndex: 0, position: "relative" }}
                      >
                        <Image src="facade-montgolfiere" />
                      </div>
                      <div
                        ref={addToBoxesSliders}
                        className="description-box-services"
                      >
                        {" "}
                        <div className="inside-box">
                          <div className="title">
                            <Trans>Parking Privé Sécurisé</Trans>
                          </div>
                          <div className="separation-line"></div>
                          <div className="text">
                            <Trans>
                              L'hôtel bénéficie d'un parking payant et sécurisé.
                              Suivant disponibilité, sa reservation au préalable
                              est nécessaire.
                            </Trans>
                            <div style={{ fontWeight: "bold", marginTop: 20 }}>
                              <Trans>Prix: 12 euros par nuit</Trans>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="services-area" style={{ width: "100%" }}>
                    <div className="image-container">
                      <div style={{ zIndex: 0, position: "relative" }}>
                        <Image src="breakfast-continental" />
                      </div>
                      <div
                        ref={addToBoxesSliders}
                        className="description-box-services"
                      >
                        <div className="inside-box">
                          <div className="title">
                            <Trans>Petit Déjeuner Continental</Trans>
                          </div>
                          <div className="separation-line"></div>
                          <div className="text">
                            <Trans>
                              Un moment à savourer, un moment où l'on prend son
                              temps pour déguster notre buffet, un moment ou
                              l'on s'attarde autour d'un café. Il est temps pour
                              vous de débuter votre journée à Amboise de la plus
                              belle manière qu'il soit.
                            </Trans>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="services-area" style={{ width: "100%" }}>
                    <div className="image-container">
                      <div style={{ zIndex: 0, position: "relative" }}>
                        <Image src="conciergerie" />
                      </div>
                      <div
                        ref={addToBoxesSliders}
                        className="description-box-services"
                      >
                        <div className="inside-box">
                          <div className="title">
                            <Trans>Conciergerie</Trans>
                          </div>
                          <div className="separation-line"></div>
                          <div className="text">
                            <Trans>
                              Nous nous ferons un plaisir de mettre à profit nos
                              connaissances pour rendre votre séjour le meilleur
                              possible avec des services personnalisés
                              (réservations de restaurants, taxis, ou visites
                              culturelles et autres lieux emblématiques à
                              découvrir).
                            </Trans>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="image-container">
                    <div
                      ref={addToImages}
                      style={{ zIndex: 0, position: "relative" }}
                    >
                      <Image src="melba" />
                    </div>
                    <div
                      ref={addToBoxesSliders}
                      className="description-box-services"
                    >
                      <div className="inside-box">
                        <div className="title">
                          <Trans>Welcome</Trans>
                        </div>
                        <div className="separation-line"></div>
                        <div className="text">
                          <Trans>
                            Nous aimons nos amis à quatre pattes, ils sont donc
                            les bienvenus !
                          </Trans>
                          <div style={{ fontWeight: "bold", marginTop: 20 }}>
                            <Trans>
                              Sur demande, nous acceptons les chiens de moins de
                              10 kilos - 25 euros de supplément par nuit.
                            </Trans>
                          </div>
                          <div style={{ fontWeight: "bold", marginTop: 20 }}>
                            <Trans>
                              Prix: 25 euros de supplément par nuit.
                            </Trans>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="services-area" style={{ width: "100%" }}>
                    <div className="image-container">
                      <div style={{ zIndex: 0, position: "relative" }}>
                        <Image src="ascenceur" />
                      </div>
                      <div
                        ref={addToBoxesSliders}
                        className="description-box-services"
                      >
                        <div className="inside-box">
                          <div className="title">
                            <Trans>Ascenceur</Trans>
                          </div>
                          <div className="separation-line"></div>
                          <div className="text">
                            <Trans>
                              Un ascenceur vitré est à votre disposition pour
                              desservir le 1er et 2ème étage dans la maison de
                              maître.
                            </Trans>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div
                            className='services-area'
                            style={{width: '100%'}}
                        >
                            <div className="image-container">
                                <Image src='rose-garden-facade'/>
                            </div>
                        </div>
                        <div
                            className='services-area'
                            style={{width: '100%'}}
                        >
                            <div className="image-container">
                                <Image src='rose-garden-facade'/>
                            </div>
                        </div> */}
                  {/* </AwesomeSlider> */}
                </Carousel>
              </div>
            )}
            {windowWidth < 800 && (
              <div
              // style={{display: windowWidth < 800?'block':'none'}}
              >
                <AwesomeSlider
                  organicArrows={false}
                  animation="scaleOutAnimation"
                  cssModule={AwesomeSliderStyles}
                  mobileTouch={true}
                  buttonContentLeft={<div ref={el => (leftButton = el)} />}
                  buttonContentRight={<div ref={el => (rightButton = el)} />}
                  onTransitionEnd={e => {
                    setSlideNumber(e.currentIndex)
                  }}
                  onFirstMount={e => {
                    setTotalSlides(e.slides)
                  }}
                  bullets={false}
                >
                  <div className="services-area" style={{ width: "100%" }}>
                    <div className="image-container">
                      <div ref={addToImages} style={{ overflow: "hidden" }}>
                        <Image src="facade-montgolfiere" />
                      </div>
                      <div
                        ref={addToBoxesSliders}
                        className="description-box-services"
                      >
                        {" "}
                        <div className="inside-box">
                          <div className="title">
                            <Trans>Parking Privé Sécurisé</Trans>
                          </div>
                          <div className="separation-line"></div>
                          <div className="text">
                            <Trans>
                              L'hôtel bénéficie d'un parking payant et sécurisé.
                              Suivant disponibilité, sa reservation au préalable
                              est nécessaire.
                            </Trans>
                            <div style={{ fontWeight: "bold", marginTop: 20 }}>
                              <Trans>Prix: 12 euros par nuit</Trans>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="services-area" style={{ width: "100%" }}>
                    <div className="image-container">
                      <div ref={addToImages}>
                        <Image src="breakfast-continental" />
                      </div>
                      <div
                        ref={addToBoxesSliders}
                        className="description-box-services"
                      >
                        <div className="inside-box">
                          <div className="title">
                            <Trans>Petit Déjeuner Continental</Trans>
                          </div>
                          <div className="separation-line"></div>
                          <div className="text">
                            <Trans>
                              Un moment à savourer, un moment où l'on prend son
                              temps pour déguster notre buffet, un moment ou
                              l'on s'attarde autour d'un café. Il est temps pour
                              vous de débuter votre journée à Amboise de la plus
                              belle manière qu'il soit.
                            </Trans>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="services-area" style={{ width: "100%" }}>
                    <div className="image-container">
                      <div ref={addToImages}>
                        <Image src="conciergerie" />
                      </div>
                      <div
                        ref={addToBoxesSliders}
                        className="description-box-services"
                      >
                        <div className="inside-box">
                          <div className="title">
                            <Trans>Conciergerie</Trans>
                          </div>
                          <div className="separation-line"></div>
                          <div className="text">
                            <Trans>
                              Nous nous ferons un plaisir de mettre à profit nos
                              connaissances pour rendre votre séjour le meilleur
                              possible avec des services personnalisés
                              (réservations de restaurants, taxis, ou visites
                              culturelles et autres lieux emblématiques à
                              découvrir).
                            </Trans>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="services-area" style={{ width: "100%" }}>
                    <div className="image-container">
                      <div ref={addToImages}>
                        <Image src="melba" />
                      </div>
                      <div
                        ref={addToBoxesSliders}
                        className="description-box-services"
                      >
                        <div className="inside-box">
                          <div className="title">
                            <Trans>Welcome</Trans>
                          </div>
                          <div className="separation-line"></div>
                          <div className="text">
                            <Trans>
                              Nous aimons nos amis à quatre pattes, ils sont
                              donc les bienvenus !
                            </Trans>
                            <div style={{ fontWeight: "bold", marginTop: 20 }}>
                              <Trans>
                                Sur demande, nous acceptons les chiens de moins
                                de 10 kilos - 25 euros de supplément par nuit.
                              </Trans>
                            </div>
                            <div style={{ fontWeight: "bold", marginTop: 20 }}>
                              <Trans>
                                Prix: 25 euros de supplément par nuit.
                              </Trans>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="services-area" style={{ width: "100%" }}>
                    <div className="image-container">
                      <div ref={addToImages}>
                        <Image src="ascenceur" />
                      </div>
                      <div
                        ref={addToBoxesSliders}
                        className="description-box-services"
                      >
                        <div className="inside-box">
                          <div className="title">
                            <Trans>Ascenceur</Trans>
                          </div>
                          <div className="separation-line"></div>
                          <div className="text">
                            <Trans>
                              Un ascenceur vitré est à votre disposition pour
                              desservir le 1er et 2ème étage dans la maison de
                              maître.
                            </Trans>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </AwesomeSlider>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = ({
  isTouchScreen,
  showServicesCursors,
  scrollOffset,
  scrolltriggerReady,
  windowWidth,
}) => {
  return {
    isTouchScreen,
    showServicesCursors,
    scrollOffset,
    scrolltriggerReady,
    windowWidth,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateShowCursors: bool =>
      dispatch({ type: `UPDATE_SHOW_SERVICES_CURSORS`, payload: bool }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Services)
