import React from "react"

const AcrSvg = ({ color }) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100px"
      viewBox="0 0 747.0773 321.3482"
    >
      <path
        d="M267.0279,793.67v-.6412h43.2907L412.7626,480.34,529.132,793.029h34.9364v.6412h-144.02v-.6412h57.4343l-42.431-114.232H348.4656L310.9644,793.029h62.3641v.6412ZM348.684,678.1558h86.1537L389.1867,554.71Z"
        transform="translate(-266.5279 -478.8252)"
        style={{ fill: color, stroke: color, strokeMiterlimit: "10" }}
      />
      <path
        d="M528.1888,541.2075c27.8645-38.1517,72.4422-61.5092,117.02-60.868a175.3833,175.3833,0,0,1,60.0038,10.0734c15.4354,6.0032,44.15,14.1437,46.5106-10.0734h.6412V581.2828h-.6412c0-22.2889.8549-35.7913-10.9331-55.2923-7.2855-11.788-14.1436-19.7194-26.5728-29.3607-12.6475-9.6459-46.5106-15.6445-69.0085-15.6445-33.863,0-55.0785,16.2857-72.01,48.8617s-25.29,62.5825-25.29,111.23c0,97.0868,29.3607,157.95,97.3005,157.95,22.4979,0,56.361-5.7848,68.7948-15.6444,12.8566-9.86,19.2872-18.2186,26.5728-30.22,11.788-19.7147,11.1468-38.5745,11.1468-54.4327h.6412V799.6734h-.6412a60.8318,60.8318,0,0,0-6.8582-8.1452c-3.429-3.429-10.5008-7.29-19.5056-7.29-7.4993,0-11.7833,2.1466-20.1468,5.362a175.3857,175.3857,0,0,1-60.0038,10.0734c-37.72,0-73.943-15.217-101.5891-41.5808-13.93-13.284-25.072-30.0018-33.4309-50.3624s-12.4292-42.4357-12.4292-66.6528C497.7594,602.2845,509.5427,566.9254,528.1888,541.2075Z"
        transform="translate(-266.5279 -478.8252)"
        style={{ fill: color, stroke: color, strokeMiterlimit: "10" }}
      />
      <path
        d="M722.3623,793.67v-.6412h41.1486V486.9839H722.3623v-.6412H851.8066c32.5807,0,58.5077,9.2138,72.87,24.0033,14.3621,14.5712,21.4339,32.1485,21.4339,51.222,0,37.0738-23.1484,57.8618-60.868,66.4344,21.8614,3.4291,38.5745,15.217,49.935,27.005,11.5742,11.7879,17.359,29.1469,17.359,44.7914v43.2906c0,38.3654,8.5726,51.8679,32.79,51.8679,8.791,0,19.9331-3.2153,27.4324-5.5711l.2138.6412a141.94,141.94,0,0,1-44.7914,7.0719,75.1637,75.1637,0,0,1-39.4295-10.5009c-12.6475-7.4993-20.7927-22.7163-20.7927-43.2906V693.1544c0-16.0719-2.7832-38.3608-11.5742-49.2891-8.568-10.933-22.93-15.8629-40.289-15.8629H808.0886V793.029h41.1486v.6412Zm85.7263-166.309h54.8648c15.4307,0,25.9316-6.2169,31.2889-17.359,5.3573-11.36,7.29-23.79,7.29-44.5776,0-19.501-3.8611-37.2921-11.7926-52.9366-7.713-15.6445-22.4979-25.5041-41.3623-25.5041h-40.289Z"
        transform="translate(-266.5279 -478.8252)"
        style={{ fill: color, stroke: color, strokeMiterlimit: "10" }}
      />
    </svg>
  )
}

export default AcrSvg
