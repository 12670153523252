import React, { useRef, useEffect } from "react"
import gsap from "gsap"
import { connect } from "react-redux"
import { Image } from "../gatsby-images/image"
import ScrollTrigger from "gsap/ScrollTrigger"

const HorizontalCarousel = ({ scrolltriggerReady, windowWidth }) => {
  let container = useRef(null)
  let image = useRef(null)

  let sectionsRef = useRef([])
  sectionsRef.current = []
  const addToSectionsRef = el => {
    if (el && !sectionsRef.current.includes(el)) {
      sectionsRef.current.push(el)
    }
  }

  let wrappersRef = useRef([])
  wrappersRef.current = []
  const addToWrappersRef = el => {
    if (el && !wrappersRef.current.includes(el)) {
      wrappersRef.current.push(el)
    }
  }

  //tweens
  let wrapperRefsTweens = useRef([])

  const addToWrappersRefTweens = el => {
    if (el && !wrapperRefsTweens.current.includes(el)) {
      wrapperRefsTweens.current.push(el)
    }
  }

  const showDemo = () => {
    sectionsRef = sectionsRef.current
    wrappersRef = wrappersRef.current

    sectionsRef.forEach((section, index) => {
      const w = wrappersRef[index]
      const [x, xEnd] =
        index % 2
          ? ["100%", (w.scrollWidth - section.offsetWidth) * -1]
          : [w.scrollWidth * -1, 0]
      //avoid avid two same animations when re-renders
      ScrollTrigger.getById(`horizontal-carousel-${index}`) &&
        ScrollTrigger.getById(`horizontal-carousel-${index}`).kill()
      const animation = gsap.fromTo(
        w,
        { x },
        {
          x: xEnd,
          scrollTrigger: {
            id: `horizontal-carousel-${index}`,
            trigger: section,
            scrub: 1,
          },
        }
      )
      addToWrappersRefTweens(animation)
    })
  }

  useEffect(() => {
    const isTouchDevice = "ontouchstart" in window
    wrapperRefsTweens.current && wrapperRefsTweens.current.map(st => st.kill())
    scrolltriggerReady && !isTouchDevice && showDemo()
    isTouchDevice && showDemo()
  }, [scrolltriggerReady, windowWidth])

  useEffect(() => {
    return () => {
      wrapperRefsTweens.current &&
        wrapperRefsTweens.current.map(st => st.kill())
    }
  }, [])
  return (
    <div className="horizontal-chic">
      <section className="demo-gallery" ref={el => addToSectionsRef(el)}>
        <ul className="wrapper" ref={el => addToWrappersRef(el)}>
          <li>
            <Image key="" src="prestige-rykiel" />
          </li>
          <li>
            <Image key="" src="facade-acr" />
          </li>
          <li>
            <Image key="" src="laptop-salon" />
          </li>
        </ul>
      </section>
      <section className="demo-gallery" ref={el => addToSectionsRef(el)}>
        <ul className="wrapper" ref={el => addToWrappersRef(el)}>
          <li>
            <Image key="" src="fleurs-parasol" />
          </li>
          <li>
            <Image key="" src="mirror-stairs" />
          </li>
          <li>
            <Image key="" src="privilege-room" />
          </li>
        </ul>
      </section>
    </div>
  )
}

const mapStateToProps = ({ scrolltriggerReady, windowWidth }) => {
  return { scrolltriggerReady, windowWidth }
}

export default connect(mapStateToProps, null)(HorizontalCarousel)
