import React from "react"
import { Trans } from "@lingui/macro"
import { LocalizedLink, LocalesList } from "gatsby-theme-i18n"

const TextRoomSlider = () => {
  return (
    <div className="introduction">
      <div className="text-slider">
        <div className="offset-2">
          <p className="title">
            <Trans>THE EXPERIENCE</Trans>
          </p>
          <h2>
            <Trans>Purely</Trans>
          </h2>
          <h2 style={{ marginBottom: "6vw" }}>
            <Trans>Original</Trans>
          </h2>
        </div>
        <div className="offset-1 description-container">
          <div style={{ minWidth: 50 }} className="line col-1"></div>
          <p className="col-sm-6 description">
            <Trans>
              In Amboise historical town center, the hotel is an expression of
              the cultural generosity of the Loire Valley and its modern spirit.
            </Trans>
          </p>
        </div>
      </div>
    </div>
  )
}

export default TextRoomSlider
