import React, { useEffect, useRef, useState } from "react"
import gsap from "gsap"
import useWindowDimensions from "./windowDimensions"
import { withPrefix } from "gatsby"
import { connect } from "react-redux"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Trans } from "@lingui/macro"
import { Image } from "../../gatsby-images/image"
import { console } from "globalthis/implementation"

const InstagramGallery = ({
  updateContainerHeight,
  scrolltriggerReady,
  loadingImgs,
}) => {
  // const {posts} = props

  let gridContainer = useRef([])
  let grid = useRef(null)
  let centerPiece = useRef(null)
  let centerBlock = useRef(null)

  let [containerHeight, setContainerHeight] = useState(4100)
  let [updatedHeight, setUpdatedHeight] = useState(false)

  const gridBlock = useRef([])
  const addToBlock = el => {
    if (el && !gridBlock.current.includes(el)) {
      gridBlock.current.push(el)
    }
  }

  let gridLayer = useRef([])
  const addToGridLayer = el => {
    if (el && !gridLayer.current.includes(el)) {
      gridLayer.current.push(el)
    }
  }

  //tweens
  const animationTimeline = useRef(null)

  const { height, width } = useWindowDimensions()

  const scrollingArea = height * 3.4 * 0.9

  let positionCenter = (height * 3.4) / 5

  useEffect(() => {
    const currGridLayer = gridLayer.current
    const currGridBlock = gridBlock.current

    const windowInnerWidth = window.innerWidth

    if (scrolltriggerReady) {
      if (windowInnerWidth > 576) {
        let gridBlockNotCenter = currGridBlock.filter(e => {
          return e !== centerBlock
        })

        gsap.to(gridBlockNotCenter, { display: "none" })
        ScrollTrigger.getById("animation-instagram") &&
          ScrollTrigger.getById("animation-instagram").kill()
        animationTimeline.current = gsap
          .timeline({
            id: "animation-instagram",
            scrollTrigger: {
              trigger: centerBlock,
              start: "top top",
              end: `+=${scrollingArea}`,
              scrub: true,
              pin: grid,
              anticipatePin: 1,
            },
          })

          .set(gridBlockNotCenter, { autoAlpha: 1, display: "block" })
          .from(currGridLayer, {
            scale: 3.3333,
            ease: "none",
            transformOrigin: "44.9% 50%",
          })

        // currGridBlock.map((t,i) => {
        //     var daString="<Image src='text' />";
        //     var daParent=document.getElementById(`ig-gallery-${i}`);
        //     daParent.innerHTML=daString;
        // })

        // gsap.set(currGridBlock, {backgroundImage: i => `url(${withPrefix(`/instagram/instagram-${i}.jpg`)})`});
        gsap.to([centerPiece, currGridBlock], { autoAlpha: 1, duration: 0.5 })

        //access height of pin-spacer
        if (!updatedHeight) {
          setTimeout(() => {
            let gridHeight =
              animationTimeline.current.scrollTrigger.spacer.offsetHeight
            setContainerHeight(gridHeight)
            //update instagram container height + add insta text below it
            updateContainerHeight(gridHeight + 300)
            // setUpdatedHeight(true)
          }, 1000)
        }
      } else {
        // gsap.set(currGridBlock, {backgroundImage: i => `url(${withPrefix(`/instagram/instagram-${i}.jpg`)})`});
        gsap.to([centerPiece, currGridBlock], { autoAlpha: 1, duration: 0.5 })
        if (!updatedHeight) {
          setTimeout(() => {
            let gridHeight = (window.innerHeight / 2) * 0.9
            setContainerHeight(gridHeight)
            //update instagram container height + add insta text below it
            updateContainerHeight(gridHeight + 300)
            // setUpdatedHeight(true)
          }, 1000)
        }
      }
    }
  }, [updatedHeight, scrolltriggerReady])

  useEffect(() => {
    return () => {
      animationTimeline.current && animationTimeline.current.kill()
      setUpdatedHeight(false)
    }
  }, [])

  return (
    <>
      <div
        className="grid-container"
        style={{ position: "relative", height: containerHeight }}
        ref={gridContainer}
      >
        <div className="grid" ref={el => (grid = el)}>
          <div className="gridLayer" ref={el => addToGridLayer(el)}>
            <div
              className="gridBlock"
              id="ig-gallery-0"
              ref={el => addToBlock(el)}
            >
              {loadingImgs && <Image loadingImgs={loadingImgs} src="insta-1" />}
            </div>
          </div>
          <div className="gridLayer" ref={el => addToGridLayer(el)}>
            <div
              className="gridBlock"
              id="ig-gallery-1"
              ref={el => addToBlock(el)}
            >
              {loadingImgs && <Image loadingImgs={loadingImgs} src="insta-2" />}
            </div>
          </div>
          <div className="gridLayer" ref={el => addToGridLayer(el)}>
            <div
              className="gridBlock"
              id="ig-gallery-2"
              style={{ backgroundColor: "blue" }}
              ref={el => addToBlock(el)}
            >
              {loadingImgs && <Image loadingImgs={loadingImgs} src="insta-3" />}
            </div>
          </div>
          <div
            className="gridLayer centerPiece"
            ref={el => {
              addToGridLayer(el)
              centerPiece = el
            }}
          >
            <div
              className="gridBlock centerBlock"
              id="ig-gallery-3"
              ref={el => {
                addToBlock(el)
                centerBlock = el
              }}
            >
              {loadingImgs && <Image loadingImgs={loadingImgs} src="insta-4" />}
            </div>
          </div>
          <div className="gridLayer" ref={el => addToGridLayer(el)}>
            <a
              href="https://instagram.com/hotel_acr"
              target="_blank"
              style={{
                width: "100%",
                height: "100%",
                cursor: "pointer",
                pointerEvents: "all",
                zIndex: 10,
              }}
              aria-label="instagram link"
            >
              <div
                className="gridBlock"
                id="ig-gallery-4"
                ref={el => addToBlock(el)}
              >
                {loadingImgs && (
                  <Image loadingImgs={loadingImgs} src="insta-5" />
                )}
              </div>
            </a>
          </div>
          <div className="gridLayer" ref={el => addToGridLayer(el)}>
            <div
              className="gridBlock"
              id="ig-gallery-5"
              ref={el => addToBlock(el)}
            >
              {loadingImgs && <Image loadingImgs={loadingImgs} src="insta-6" />}
            </div>
          </div>
          <div className="gridLayer" ref={el => addToGridLayer(el)}>
            <div
              className="gridBlock"
              id="ig-gallery-6"
              ref={el => addToBlock(el)}
            >
              {loadingImgs && <Image loadingImgs={loadingImgs} src="insta-7" />}
            </div>
          </div>
          <div className="gridLayer" ref={el => addToGridLayer(el)}>
            <div
              className="gridBlock"
              id="ig-gallery-7"
              ref={el => addToBlock(el)}
            >
              {loadingImgs && <Image loadingImgs={loadingImgs} src="insta-8" />}
            </div>
          </div>
          <div className="gridLayer" ref={el => addToGridLayer(el)}>
            <div
              className="gridBlock"
              id="ig-gallery-8"
              ref={el => addToBlock(el)}
            >
              {loadingImgs && <Image loadingImgs={loadingImgs} src="insta-9" />}
            </div>
          </div>
        </div>
      </div>
      <div className="row instagram-text" style={{ height: 300 }}>
        <div className="col-sm-10 offset-2">
          <p className="small-title">INSTAGRAM</p>
          <div className="text">
            <Trans>Suivez-Nous</Trans>
          </div>
          <div className="text" style={{ fontStyle: "italic" }}>
            @hotel_acr
          </div>
          <div className="offset-1 button-area">
            <Trans>Join the adventure!</Trans>
            <a href="https://www.instagram.com/hotel_acr" target="_blank">
              <div className="button" style={{ textTransform: "uppercase" }}>
                <Trans>SUIVEZ-NOUS</Trans>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ scrolltriggerReady, windowWidth }) => {
  return { scrolltriggerReady, windowWidth }
}

const mapDispatchToProps = dispatch => {
  return {
    updateContainerHeight: containerHeight =>
      dispatch({ type: `UPDATE_CONTAINER_HEIGHT`, payload: containerHeight }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstagramGallery)
