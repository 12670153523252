import React, { useEffect, useRef } from "react"
import { Image } from "../gatsby-images/image"
import gsap from "gsap"
// import FaceAcr from '../../images/face-acr.jpg'
import useMousePosition from "../../hooks/useMousePosition"
import BannerSvg from "./BannerSvg"
import Logo from "../share/Logo"
import { connect } from "react-redux"
import Video from "./video/Video"
import CloseCursor from "../../images/video-cursor-clos.png"

const Banner = ({
  bannerLoaded,
  scrollOffset,
  isVideoPlaying,
  isTouchScreen,
}) => {
  let videoCursor = useRef(null)
  let banner = useRef(null)
  const { x, y } = useMousePosition()
  let videoCloseCursor = useRef(null)

  // const fullyLoaded = () => {
  //   bannerLoaded()
  // }

  // useEffect(() => {
  //   bannerLoaded()
  // })

  // useEffect(() => {
  //   if(isTouchScreen){

  //   }
  // }, [isTouchScreen])

  // let moveCircle = () => {
  //   const bannerWidth = banner.getBoundingClientRect().width
  //   const bannerHeight = banner.getBoundingClientRect().height
  //   if(isVideoPlaying) {
  //   //   gsap.to(videoCloseCursor, {opacity: 1, pointerEvents: 'none'})
  //   //   gsap.to(videoCloseCursor, {
  //   //     duration: 1.5,
  //   //     ease: "power2.out",
  //   //     css: {
  //   //       // 75 equal half of the width and height of the cursor svg
  //   //       left: x - 25,
  //   //       top: y - 25 + scrollOffset
  //   //     }
  //   // });
  //   } else {
  //       gsap.to(videoCursor, {
  //         duration: 1.5,
  //         ease: "power2.out",
  //         css: {
  //           // 75 equal half of the width and height of the cursor svg
  //           left: x - 75,
  //           top: y - 75 + scrollOffset,

  //         }
  //     });
  //   }
  // }

  // let moveBackCircleToCenter = () => {
  //   const bannerWidth = banner.getBoundingClientRect().width
  //   const bannerHeight = banner.getBoundingClientRect().height

  //   gsap.to(videoCursor, {
  //       duration: 5,
  //       ease: "power2.out",
  //       css: {
  //         left: bannerWidth / 2 - 75,
  //         top: bannerHeight / 2 - 75
  //       }
  //   });
  // }

  // useEffect(() => {
  //   if(isVideoPlaying) {
  //     // gsap.to(videoCloseCursor, {opacity: 1, duration: 1, pointerEvents: 'none', cursor: 'none'})
  //   } else {
  //     gsap.to(videoCloseCursor, {opacity: 0, duration: 1})

  //   }
  // }, [isVideoPlaying])

  return (
    <>
      <div
        className="banner"
        ref={el => (banner = el)}
        // onMouseMove={() => !isTouchScreen && moveCircle()}
        // onMouseLeave={() => !isTouchScreen &&  moveBackCircleToCenter()}
      >
        {/* <h1 className='main-headline small'>Au Charme Rabelaisien</h1> */}
        <div className="logo-banner">
          <Logo color="white" />
        </div>
        {/* <div
          className='video-close-cursor'
          ref={el => videoCloseCursor = el}
        >
          <img src={CloseCursor} />
        </div> */}
        <div className="cursor-video" ref={el => (videoCursor = el)}>
          <BannerSvg />
        </div>
        <div className="image">
          {/* <Image 
            loaded={fullyLoaded}
            src='banner-image'
           /> */}
          <Video />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ scrollOffset, isVideoPlaying, isTouchScreen }) => {
  return { scrollOffset, isVideoPlaying, isTouchScreen }
}
const mapDispatchToProps = dispatch => {
  return {
    bannerLoaded: () => dispatch({ type: `IMG_LOADED` }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Banner)
