import React, { useRef, useEffect, useState } from "react"

// import Massage from '../../../images/video-massage.mp4'
// import HotAirBalloon from '../../../images/hot-air-balloon.mp4'
// import Golf from '../../../images/golf.mp4'
// import Cocktails from '../../../images/cocktails.mp4'
// import Biking from '../../../images/biking.mp4'
// import Pool from '../../../images/pool-ula.mp4'
// import Chateau from '../../../images/chateau-sunrise.mp4'
// import Beauval from '../../../images/loups-beauval.mp4'
// import CursorEl from './cursor'

import { Trans } from "@lingui/macro"

import TransitionLink from "gatsby-plugin-transition-link"
import { connect } from "react-redux"
import gsap from "gsap"

const activityList = {
  en: ["Spa", "Bar", "Beauval's Zoo", "Biking", "Hot Air Ballon", "Golf"],
  fr: ["Spa", "Bar", "Zoo de Beauval", "Vélo", "Montgolfière", "Golf"],
  ru: ["спа", "Бар", "Зоопарк Боваль", "Велосипед", "Монгольфьер", "Гольф"],
}

const Activities = ({
  showTransitionBanner,
  hideTransitionBanner,
  scrollOffset,
  resetOffset,
  language,
}) => {
  let cursorelement = useRef(null)
  let cancelCursorElement = useRef(null)
  const [mouseInside, setMouseInside] = useState("")

  let cursor

  const handleLeavingHover = e => {
    if (mouseInside) {
      const word = mouseInside.children
      gsap.fromTo(word, { rotate: 12 }, { rotate: 0, stagger: 0.1, delay: 1 })
      setMouseInside("")
    }
  }

  useEffect(() => {
    if (mouseInside) {
      // cursor = new CursorEl(cursorelement, scrollOffset)
      const word = mouseInside.children
      // const wordToLetter = word.map(word => )

      gsap.to(word, { rotate: 12, stagger: 0.1 })
    }
  }, [mouseInside, scrollOffset])

  const stringArray = new Array()
  return (
    <div>
      <div className="our-activities offset-2 col-sm-10">
        <p className="title">
          <Trans>NOS ACTIVITÉS</Trans>
        </p>
        <div className="">
          <ul className="row activities-list hero-inner-links">
            {activityList[language].map(activity => {
              return (
                <li
                  key={activity}
                  data-img-src="massages-activities"
                  className="hero-inner-link-item"
                  onClick={() => showTransitionBanner()}
                >
                  <TransitionLink
                    to={`/${language}/activities`}
                    exit={{
                      delay: 2,
                    }}
                    entry={{
                      trigger: ({ node, e, exit, entry }) => {
                        hideTransitionBanner()
                        resetOffset(true)
                      },
                    }}
                  >
                    <div
                      onMouseEnter={e =>
                        e.target.children.length && setMouseInside(e.target)
                      }
                      onMouseLeave={() => handleLeavingHover()}
                    >
                      {activity.split("").map((letter, index) => {
                        if (letter === " ") {
                          return (
                            <span
                              key={`${index}-${letter}-activity-${activity}`}
                            >
                              &nbsp;
                            </span>
                          )
                        } else {
                          return (
                            <span
                              key={`${letter}-activity-${index}-${activity}`}
                            >
                              {letter}
                            </span>
                          )
                        }
                      })}
                    </div>
                  </TransitionLink>
                </li>
              )
            })}
            {/* <li 
              data-img-src='massages-activities' 
              className='hero-inner-link-item'
              onClick={() => showTransitionBanner()}
            >
            <TransitionLink 
                        to={`/${language}/activities`} 
                        exit={{
                            delay: 2,
                        }}
                        entry={{
                            trigger: ({ node, e, exit, entry }) => {
                                hideTransitionBanner()
                                resetOffset(true)
                            }
                        }}
            >
                <div
                  onMouseEnter={(e) => setMouseInside(e.target)}
                  onMouseLeave={() => handleLeavingHover()}
                >
                  <span>E</span><span>s</span><span>p</span><span>a</span><span>c</span><span>e</span>
                </div>
              </TransitionLink>
            </li>

            <li 
              data-img-src='bar-activities' 
              className='hero-inner-link-item'
              onClick={() => showTransitionBanner()}
            >
            <TransitionLink 
                        to={`/${language}/activities`} 
                        exit={{
                            delay: 2,
                        }}
                        entry={{
                            trigger: ({ node, e, exit, entry }) => {
                                hideTransitionBanner()
                                resetOffset(true)
                            }
                        }}
            >
                <Trans>Bar</Trans>
            </TransitionLink>
            </li>
            <li 
              data-img-src='beauval-activities' 
              className='hero-inner-link-item'
              onClick={() => showTransitionBanner()}
            >
            <TransitionLink 
                        to={`/${language}/activities`} 
                        exit={{
                            delay: 2,
                        }}
                        entry={{
                            trigger: ({ node, e, exit, entry }) => {
                                hideTransitionBanner()
                                resetOffset(true)
                            }
                        }}
            >
                <Trans>Zoo de Beauval</Trans>
            </TransitionLink>
            </li>
            <li 
              data-img-src='pool-activities' 
              className='hero-inner-link-item'
              onClick={() => showTransitionBanner()}
            >
            <TransitionLink 
                        to={`/${language}/activities`} 
                        exit={{
                            delay: 2,
                        }}
                        entry={{
                            trigger: ({ node, e, exit, entry }) => {
                                hideTransitionBanner()
                                resetOffset(true)
                            }
                        }}
            >
                <Trans>Piscine</Trans>
            </TransitionLink>
            </li>
            <li 
              data-img-src='castle-activities' 
              className='hero-inner-link-item'
              onClick={() => showTransitionBanner()}
            >
            <TransitionLink 
                        to={`/${language}/activities`} 
                        exit={{
                            delay: 2,
                        }}
                        entry={{
                            trigger: ({ node, e, exit, entry }) => {
                                hideTransitionBanner()
                                resetOffset(true)
                            }
                        }}
            >
                  <Trans>Visite des chateaux</Trans>
            </TransitionLink>
            </li>
            <li 
              data-img-src='bike-activities' 
              className='hero-inner-link-item'
              onClick={() => showTransitionBanner()}
            >
            <TransitionLink 
                        to={`/${language}/activities`} 
                        exit={{
                            delay: 2,
                        }}
                        entry={{
                            trigger: ({ node, e, exit, entry }) => {
                                hideTransitionBanner()
                                resetOffset(true)
                            }
                        }}
            >
                <Trans>Vélo</Trans> 
            </TransitionLink>
            </li>
            <li 
              data-img-src='balloon-activities' 
              className='hero-inner-link-item'
              onClick={() => showTransitionBanner()}
            >
            <TransitionLink 
                        to={`/${language}/activities`} 
                        exit={{
                            delay: 2,
                        }}
                        entry={{
                            trigger: ({ node, e, exit, entry }) => {
                                hideTransitionBanner()
                                resetOffset(true)
                            }
                        }}
            >
                <Trans>Montgolfière</Trans>
            </TransitionLink>
            </li>
            <li 
              data-img-src='golf-activities' 
              className='hero-inner-link-item'
              onClick={() => showTransitionBanner()}
            >
            <TransitionLink 
                        to={`/${language}/activities`} 
                        exit={{
                            delay: 2,
                        }}
                        entry={{
                            trigger: ({ node, e, exit, entry }) => {
                                hideTransitionBanner()
                                resetOffset(true)
                            }
                        }}
            >
                <Trans>Golf</Trans>
            </TransitionLink>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ scrollOffset, language }) => {
  return { scrollOffset, language }
}

const mapDispatchToProps = dispatch => {
  return {
    showTransitionBanner: () => dispatch({ type: `IMG_NOT_LOADED` }),
    hideTransitionBanner: () => dispatch({ type: `IMG_LOADED` }),
    resetOffset: bool => dispatch({ type: "RESET_OFFSET", payload: bool }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Activities)
